
.fund > div > section {
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

.fund .performance {
    background-color: #FFF;
    min-height: 200px;
}
.fund .performance > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fund .performance .description {
    text-align: left;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
}
.fund .performance .description img {
    height: 150px;
}

.fund .performance .description a {
    color: #0075C9;
    font-weight: bold;
    text-decoration: none;
    margin: 0;
    font-size: 0.8em;
    position: relative;
    display: flex;
    bottom: 10px;
}

.fund .performance .description a > div {
    position: relative;
    background-color: #0075C9;
    height: 7px;
    width: 2px;
    margin: 2px;
    transform: rotate(45deg);
    top: 2px;
}
.fund .performance .description a > div:nth-child(2) {
    transform: rotate(-45deg);
    right: 6px;
    top: 6px;
}

.fund .performance .description h1 {
    color: #1b2a39;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 2.5em;
    line-height: .9em;
    max-width: 350px;
}
.fund .performance .description p {
    color: #1b2a39;
    margin: 0;
    max-width: 350px;
}

.fund .summary {
    background-color: #F5F3F0;
}
.fund .disclaimer {
    
}

.fund .summary > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.key-info table {
    border-spacing: 0;
    border-collapse: collapse;
    margin-bottom: 20px;

}
.key-info table td {
    /* border: 1px solid rgba(177,164,166,.4);
    border-bottom: 1px solid #0075C9; */
    text-align: left;
    padding: 10px;
}
.key-info table td span {
    color: #0075C9;
    font-size: 0.75em;
    font-weight: 600;
}

.fund-supplements {
    padding-bottom: 20px;
    text-align: left;
}
.fund-supplements .supplement-doc {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    color: #0075C9;
    font-weight: 600;
    padding: 1em;
    height: 100px;
    width: 100px;
    vertical-align: top;
    margin: 10px;

    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    border: 1px solid #e9ebee;
    border-bottom: 3px solid #e9ebee;
}

.fund-supplements .supplement-doc:hover {
    border-bottom: 3px solid #0075C9;
}
.fund-supplements .supplement-doc a {
    text-decoration: none;
    color: #0075C9;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.fund .graph-loading {
        display: block;
        position: relative;
        color: #4b4f56;
        background-color: #FFF;
        margin-bottom: 8px;
        counter-reset: section 2;
        font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
        font-size: 10px;
        width: 100%;
        border-radius: 4px;
}

.loading.fund {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

}

.fact-sheets {
    overflow: scroll;
}

.fact-sheets-container {
    text-align: left;
    padding-bottom: 20px;
}
.fact-sheets a,
a.application-link {
    display: inline-block;
    text-decoration: none;
    color: #0075C9;
    padding: 1em;
    margin: 10px;
    width: 25%;
    font-weight: 600;
    text-align: center;
    border: 1px solid #e9ebee;
    border-bottom: 3px solid #e9ebee;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
}
a.application-link {
    background: #0075C9;
    color: #FFF;
    position: relative;
    float: left;
    white-space: nowrap;
    width: auto;
    margin-left: 0;
    margin-bottom: 30px;
}
.fact-sheets a:hover {
    border-bottom: 3px solid #0075C9;
}


@media screen and (min-width: 1200px) {
    .fund > div > section:nth-child(1),.fund > div > section:nth-child(2) {
        max-width: 50%;
    }
    
}

@media screen and (max-width: 600px) {
    .fund .performance .description {
        flex-direction: column-reverse;
        text-align: center;
        align-items: center;
    }
    .fund .performance .description h1 {
        font-size: 1.25em;
        width: 100%;
        max-width: 100%;
    }
    .fund .performance .description p {
        width: 100%;
        max-width: 100%;
    }
    .fund .performance .description img {
        width: auto;
        height: 130px;
        position: relative;
        top: 20px;
    }
    .fund .performance .description > div {
        
        width: 100%;

    }
    .fund .performance .description > div a {
        /* justify-content: center; */
        padding-top: 20px;
        position: relative;
        bottom: 160px;
    }
}