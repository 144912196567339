.about section {
    width: 100%;
    color: #1b2a39;
    background-color: #FFF;
    height: 100vh;
    max-height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about .header {
    height: 500px;
    background-color: #FFF;
}

.about .header .team-photo {
    color: rgba(0, 0, 0, 0.15);
    margin: 20px;
    width: 100%;
    height: 90%;
    border: 5px dashed rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
}
.about .explainer {
    height: 200px;
    background-color: #F4F5F7;
}

.about .explainer p {
    margin: 10%;
}