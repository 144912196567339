.content.fs {
    padding-top: 50px;
}
.finance-services {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    height: 500px;

}
.finance-services > div {
    height: 100px;
    min-width: 350px;
    width: 80%;
    padding: 10px 15px;
    border-radius: 5px;
    background: #FFF;
    color: #1A1F39;
    border-left: 5px solid #0075C9;
    font-weight: 700;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    text-align: left;
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin: 10px 0;
}
@media only screen and (min-width: 1200px) {
    .finance-services {
        padding-right: 80px;
    }
}
@media only screen and (min-width: 1000px) {
    .finance-services {
        padding-right: 50px;
    }
}
@media only screen and (max-width: 850px) {
    .finance-services {
        padding-right: 0;
        align-items: center;
    }
    .finance-services > div {
        width: 90%;
    }
}