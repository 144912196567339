.wealth-advisory .header {
    width: 100%;
    color: #1b2a39;
    background-color: #FFF;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: block;
}

.wealth-advisory .header .content {
    height: 100%;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.wealth-advisory .header .content img {
    height: 300px;
}
.wealth-advisory h1 {
    margin: 0;
    line-height: 0.9em;
    font-size: 2.5em;
    letter-spacing: -2px;
}

.wealth-advisory .header .content .text {
    width: 500px;
    max-width: 90%;
    height: fit-content;
    z-index: 3;
    text-align: right;
    transition: all 0.5s;
}

.wealth-advisory .header .text h1 span {
    color: #0075C9;
}
.wealth-advisory .header .text h4 {
    color: #0075C9;
}
.wealth-advisory .header .text h5 {
    opacity: 0.5;
    letter-spacing: 2px;
    margin-bottom: 0.75em;
    margin-top: 0.75em;
}
.wealth-advisory .header .text p {
    width: 410px;
    max-width: 100%;
    text-align: right;
    margin-right: 0;
    float: right;
}

.portfolio-services .detail {
    background-color: #0d122c;
    color: #FFF;
    padding: 50px;
    text-align: right;
}
.wealth-advisory .detail .content {
    height: 100%;
    position: relative;
    width: 90%;
    margin-right: 5%;
}

.wealth-advisory .detail .content .text .left, .wealth-advisory .detail .content .text .right {
    margin-top: 150px;
}
.wealth-advisory .detail .content .text .left {
    text-align: left;
    max-width: 300px;
}
.wealth-advisory .detail .content .text .right {
    
    text-align: right;
    max-width: 300px;
}

@media only screen and (max-width: 850px) {
    .wealth-advisory .detail {
        text-align: center;
    }
    .wealth-advisory .header {
        height: 700px;
    }
    .wealth-advisory .header .content {
        height: 100%;
        flex-direction: column;
        justify-content: space-evenly;
        
    }
    .wealth-advisory .header .content .text {
        text-align: center;
    }
    .wealth-advisory .header .text p {
        float: none;
        text-align: center;
        width: 100%;
    }
    .wealth-advisory h1 {
        font-size: 1.75em;
    }
    .wealth-advisory .header .content img {
        height: 200px;
    }
}