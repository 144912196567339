
.big-button {
    cursor: pointer;
    font-weight: 900;
    text-decoration: none;
    font-size: 1.2em;
    padding: 0.8em;
    padding-left: 3em;
    padding-right: 3em;
    margin-top: 1em;
    position: relative;
    overflow: hidden;
    transition: all 0.5s;
}

.big-button p {
    position: relative;
    z-index: 3;
    width: fit-content;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin: 0 !important;
}
.big-button:hover p { transition: color 0.25s;}

.big-button div {
    position: absolute;
    top: 0;
    left: -20px;
    height: 100%;
    width: 0px;
    z-index: 1;
    transition: all 0.5s;
    transform: skew(.4rad);
}

.big-button:hover div {
    width: 100%;
    transition: all 0.5s;
    transform: skew(0);
    left: 0px;
}

.big-button:hover { transition: all 0.5s; }
.big-button.white {
    background-color: #0075C9;
    color: #FFF;
    border: 2px solid #0075C9;
}
.big-button.blue {
    background-color: #FFF;
    color: #0075C9;
    border: 2px solid #0075C9;
}
.big-button.beige {
    background-color: #F5F3F0;
}
.big-button.green {
    background-color: #01A63B !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.big-button.green:hover div {
    width: 0 !important;
}
.big-button.green:hover p {
    color: #FFF !important;
}
.big-button.green p {
    position: relative;
    right: 10px;
}
.big-button.green svg {
    transform: scale(0.3);
    position: absolute;
    left: 140px;
}

.big-button.blue div { 
    background-color: #0075C9;
    /* box-shadow: 0 10px 36px rgba(0,0,50,.01); */
    border-radius: 3px;
}
.big-button.white div { background-color: #FFF }
.big-button.blue:hover p { color: #FFF; }
.big-button.white:hover p { color: #0075C9; }
.big-button.white:hover { 
    border: 2px solid #FFF;
    box-shadow: 0 10px 24px rgba(0,0,50,.075);
    border-radius: 3px;
}