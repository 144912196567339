.global {
    display: block;
    color: #FFF;
    background-color: #0d122c;
    height: fit-content;
    padding-top: 5%;
    padding-left: 5%;
    padding-right: 5%;
    border-bottom-left-radius: 3px;
    border-top: none;
    padding-bottom: 1em;
    position: relative;
    text-align: left;
    min-width: 90%;
    overflow: hidden;
}
.global .map {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 0;
    height: 80%;
    max-width: 100%;
}

.global > div {
    position: relative;
    display: inline-block;
    vertical-align: top;
    height: fit-content; 
    width: 20%;
    padding-right: 5%;
    z-index: 1;
}

@media only screen and (max-width: 600px) {
    .global > div {
        display: inline-block;
        vertical-align: top;
        height: fit-content; 
        width: 20%;
        padding-right: 4%;
        min-width: 200px;
    }
}

.global h5 {
    width: fit-content;
    border-bottom: 2px solid #FFF;
}

.global ul {
    list-style: none;
    padding: 0;
    text-align: left;
}

.global ul li a {
    text-decoration: none;
    color: #FFF;
    opacity: 0.75;
    font-size: 0.75em;
}

.global ul li a:hover {
    opacity: 1;
}