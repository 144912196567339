.page-header {
    max-width: 450px;
    bottom: 100px;
    position: relative;
}
.page-header h1 {
    margin: 0;
    line-height: 0.9em;
    font-size: 2.5em;
    letter-spacing: -2px;
}
.page-header.blue > p { color: #1b2a39; }
.page-header.blue h1 { color: #1b2a39; }
.page-header.blue h1 span { color: #0075C9; }

@media only screen and (max-width: 700px) {
    .page-header {
        max-width: 100%;
        text-align: center;
    }
 }