
footer ul {
    display: flex;
    margin: 0;
    padding: 0;
}
footer #logo {
    padding-top: 10px;
}

@media (max-width: 750px) {
    .footer-container {
        height: auto;
    }
    footer ul { 
        flex-direction: column;
        padding-bottom: 50px;
    }
    footer ul li:nth-child(2) {
        padding-top: 0.25em;
        padding-bottom: 0.25em;
    }
    footer ul li:nth-child(2) h5 {
        margin-block-start: 0.67em;
        margin-block-end: 1.67em;
    }
    footer ul li:nth-child(3) {
        padding-bottom: 2.5em;
    }
}
@media (min-width: 900px) {
    .footer-container {
        height:300px;
    }
}

footer ul li {
    list-style: none;
    text-align: left;
    padding: 1em;
    padding-left: 0;
}

footer ul li p {
    font-size: 0.7em;
}

footer .email-link {
    color: #0075C9;
}

.important-links {
    width: 100%;
    min-height: 50px;
    color: #FFF;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.75em;
}

.important-links a {
    text-decoration: none;
    color: #FFF;
    padding-right: 1em;
}
.important-links a:active, .important-links a:focus {
    font-weight: 600;
    outline: none;
 }

.important-links p {
    text-align: right;
    flex-grow: 1;
    margin: 0;
}