.fund-summary {
    height: 350px;
    width: 300px;
    max-width: 90vw;
    background-color: #0d122c;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    list-style: none;
    text-align: left;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    transition: all 0.5s;
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    margin: auto;
}
.fund-summary .top, .fund-summary .bottom {
    color: #FFF;
    height: auto;
}

.fund-summary .top {
    display: flex;
    margin: 10px;
    justify-content: space-between;
}

.fund-summary .top h3, .fund-summary .top p {
    margin: 0;
    font-weight: 600;
    
}
.fund-summary .top h3 span, .fund-summary .top p span {
    color: #FFC20E;
    color: #FFF;
}
.fund-summary .top .performance {
    white-space: nowrap;
    text-align: right;
}
.fund-summary .top p {
    font-size: 12px;
}

.fund-summary .middle {
    background-color: #FFF;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.fund-summary .middle img {
    height: 120px;
}

.fund-summary.focus {
    top: 70px;
    width: 70vw;
}
.fund-summary .bottom {
    margin: 15px;
    font-size: 12px;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.fund-summary .bottom p {
    margin: 0px;
}
.fund-summary .learn-more {
    width: 130px;
    cursor: pointer;
    background-color: none;
    color: #FFC20E;
    color: #0075C9;
    font-weight: 900;
    height: 35px;
    text-decoration: none;
    font-size: 12px;
    margin-top: 15px;
    margin-bottom: 10px;
    border: 2px solid #FFC20E;
    border: 2px solid #0075C9;
    position: relative;
    overflow: hidden;
    transition: all 0.5s;
    display: flex;
    align-items: center;
}

.fund-summary .learn-more p {
    position: relative;
    z-index: 3;
    width: 100%;
    text-align: center;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin: 0 !important;
}

.fund-summary .learn-more:hover p {
    color: #FFF;
    transition: color 0.25s;
}

.fund-summary .learn-more div {
    position: absolute;
    top: 0;
    left: -20px;
    background-color: #FFC20E;
    background-color: #0075C9;
    height: 100%;
    width: 0px;
    z-index: 1;
    transition: all 0.5s;
    transform: skew(.4rad);
}

.fund-summary .learn-more:hover div {
    width: 100%;
    transition: all 0.5s;
    transform: skew(0);
    left: 0px;
}

.fund-summary .learn-more:hover {
    transition: all 0.5s;
}

@media only screen and (max-width: 500px) {
    .fund-summary {
        max-width: 70vw;
    }
    .fund-summary .top h3 {
        font-size: 0.9em;
    }
    .fund-summary .bottom p {
        font-size: 0.8em;
    }
 }
 