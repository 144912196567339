.cliff {
    background: rgb(240,252,255);
    background: -moz-linear-gradient(30deg,#0D122C 0%, #F0FCFF 20%, #8CD1F1 100%);
    background: -webkit-linear-gradient(30deg,#0D122C 0%, #F0FCFF 20%, #8CD1F1 100%);
    background: linear-gradient(30deg,#0D122C 0%, #F0FCFF 20%,  #8CD1F1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f0fcff",endColorstr="#008ad0",GradientType=1);
    background: #0D122C;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    padding: 50px 0;
}
.cliffParallax {
    background: rgb(240,252,255);
    background: -moz-linear-gradient(30deg,#0D122C 0%, #F0FCFF 20%, #8CD1F1 100%);
    background: -webkit-linear-gradient(30deg,#0D122C 0%, #F0FCFF 20%, #8CD1F1 100%);
    background: linear-gradient(30deg,#0D122C 0%, #F0FCFF 20%,  #8CD1F1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f0fcff",endColorstr="#008ad0",GradientType=1);
    
    padding: -10px;
    /* border: 5px solid #0D122C;
    border-radius: 10px; */
    
}
.cliffParallax,
.cliffText {
    width: 40%;
    position: relative;
    height: 400px;
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.cliffParallax img {
    width: 100%;
    max-width: 500px;
}
.cliff .bg {
    background: #0D122C;
    height: 150px;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 1;
}
.cliff .foreground,
.cliff .backdrop {
    display: flex;
    justify-content: center;
}
.cliff .left,
.cliff .right {
    position: absolute;
    height: 100%;
    width: 30px;
    z-index: 3;
    top: 0;
    width: 5px;
    /* display: none; */
}
.cliff .left {
    left:0;
    background: rgba(13, 18, 44, 0);
    background: -moz-linear-gradient(90deg, rgba(13, 18, 44, 1) 0%, rgba(13, 18, 44, 1) 40%, rgba(26, 31, 57, 0) 100%);
    background: -webkit-linear-gradient(90deg,rgba(13, 18, 44, 1) 0%, rgba(13, 18, 44, 1) 40%, rgba(26, 31, 57, 0) 100%);
    background: linear-gradient(90deg,rgba(13, 18, 44, 1) 0%, rgba(13, 18, 44, 1) 40%, rgba(26, 31, 57, 0) 100%);
}
.cliff .right {
    right: 0;
    background: rgba(13, 18, 44, 0);
    background: -moz-linear-gradient(-90deg, rgba(13, 18, 44, 1) 0%, rgba(13, 18, 44, 1) 40%, rgba(26, 31, 57, 0) 100%);
    background: -webkit-linear-gradient(-90deg,rgba(13, 18, 44, 1) 0%, rgba(13, 18, 44, 1) 40%, rgba(26, 31, 57, 0) 100%);
    background: linear-gradient(-90deg,rgba(13, 18, 44, 1) 0%, rgba(13, 18, 44, 1) 40%, rgba(26, 31, 57, 0) 100%);
}
/* .cliff .foreground img,
.cliff .backdrop img {
    max-width: 1000px;
} */
.cliff .clouds img {
    max-height: 300px;
}
.foregroundCover {
    background: #0D122C;
    height: 50px;
    z-index: 3;
    width: 100%;
}
@media only screen and (max-width: 850px) {
    .cliff {
        flex-direction: column;
    }
    .cliffParallax,
    .cliffText{
        width: 100%;
    }
}