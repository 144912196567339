.nav_wrap {
   position: absolute;
   height: 100%;
   width: 100%;
   overflow: hidden;
}

nav {
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color:#0075C9;
    height: 50px;
}

.nav_main {
   padding: 0;
   margin: 0;
   width: 100%;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   transition: all 0.5s;
   max-width: 1400px;
}

.nav_main li {
    list-style: none;
    height: 50px;
}

.nav_main li:nth-child(1) a {
   padding: 10px 10px;
}  

.nav_main li a {
   z-index: 2;
   font-size: 0.85em;
   padding: 15px 10px;
   font-weight: 600;
   color: #F5F3F0;
   text-decoration: none;
   display: block;
}

.nav_main li a:hover {
    color: #FFF;
    background-color: #015FA6;
    padding-bottom: 16px;
}

.nav_main li a:active, .nav_main li a:focus {
   
   outline: none;
}

.nav_main li:nth-child(1) a:active, .nav_main li:nth-child(1) a:focus {
   border-bottom: none;
   outline: none;
}

.nav_main li:nth-child(1) svg {
   fill: #F5F3F0;
}
.nav_main li:nth-child(1):hover svg {
   fill: #FFF;
}

nav #logo {
   height: 27px;
   width: auto;
}

.nav_main li:nth-child(1) a:hover {
   background-color: transparent;
}

.nav_main li:nth-child(2) {
   flex-grow: 2;
   max-width: 300px;
   margin-left: 15px;
   position: relative;
   height: 50px;
   overflow: visible;
}
.nav_main li:nth-child(3) {
   flex-grow: 1;
}

.nav_main #globe {
   height: 50px;
   width: 50px;
   background: none;
   border: none;
   stroke: #F5F3F0;
   fill: #F5F3F0;
}

.nav_main .services-btn {
   height: 50px;
   color: #F5F3F0;
   background: none;
   border: none;
   font-size: 0.85em;
   padding: 15px 10px;
   padding-right: 25px;
   font-weight: 600;
   position: relative;
}

.services-btn > div {
   height: 50px;
   width: 20px;
   padding: 0;
   margin: 0;
   position: absolute;
   top: 0;
   right: 0;
   display: flex;
   flex-direction: center;
   align-items: center;
}

.services-btn > div > div {
   background-color: #F5F3F0;
   height: 1px;
   width: 7px;
   position: relative;
   top: 1px;
   transition: 0.5s;
}

.services-btn > div > div:nth-child(1) {
   transform: rotate(45deg);
   left: 1px;
}

.services-btn > div > div:nth-child(2) {
   transform: rotate(-45deg);
   right: 2px;
}
/* .services-btn.active > div > div {
   width: 10px;
} */
.services-btn.active > div > div:nth-child(2) {
   
   transform: rotate(45deg);
}
.services-btn.active > div > div:nth-child(1) {
   transform: rotate(-45deg);
}

.nav_main #globe:hover, .nav_main .services-btn:hover {
   cursor: pointer;
   stroke: #FFF;
   fill: #FFF;
   background-color: #015FA6;
   color: #FFF;
}

.nav_main #globe:active, .nav_main #globe:focus {
   background-color: #0075C9;
   outline: none;
}

.nav_main .services-btn:active, .nav_main .services-btn:focus {
   outline: none;
   
}


nav #globe svg {
   height: 20px;
   width: 20px;
}

.contact-link {
   padding: 7px !important;
}

.contact-link > div {
  cursor: pointer;
  background-color: #0075C9;
  border: 1px solid #F5F3F0;
  color: #F5F3F0;
  padding: 0.5em 1em;
  margin: 0;
  font-weight: 600;
  transition: background-color 0.25s;
}

.contact-link:hover {
   background-color: #0075C9 !important;
}
.contact-link:hover > div {
   background-color: #015FA6;
   color: #FFF;
}

.search-bar {
   background-color: #015FA6;
   border: 1px solid rgba(245, 243, 240, 0.062);
   font-size: 0.85em;
   height: 30px;
   padding: 5px;
   color: #FFF;
   font-weight: 600;
}

.search-bar::placeholder {
   color:#d8dee6;
 }
.search-bar:focus::after {
   background-color: #0075C9;
}
.search-bar:focus {
   outline: none;
   border: 1px solid #FFF;
}
.search-btn {
   height: 30px;
   border: none;
   border-left: none;
   border: 1px solid rgba(245, 243, 240, 0.062);
   background-color: #015FA6;
}

.search-btn:hover {
   cursor: pointer;
}

.search-btn:focus {
   background-color: #FFF;
   outline: none;
}
.search-btn.focused {
   background-color: #FFF;
}
.search-icon {
   stroke: #FFF;
}
.search-icon circle {
   fill: none;
}

.search-btn.focused .search-icon, .search-btn:focus .search-icon {
   stroke: #0075C9;
}
.search-btn.focused .search-icon circle, .search-btn:focus .search-icon circle {
   fill: #FFF;
}

.burger {
   background-color: transparent;
   border: none;
   display: none;
   cursor: pointer;
   position: relative;
   height: 50px;
   width: 50px;
   float: right;
   display: flex;
   justify-content: center;
   align-items: center;
}


.burger:focus, .burger:active {
   outline: none;
}

.burger:hover {
   background-color: #0075C9;
   cursor: pointer;
}

.burger-inner {
   height: 30px;
   width: 30px;
   display: grid;
   grid-template-columns: 10px 10px 10px;
   grid-template-rows: 10px 10px 10px;
}

.burger-inner div {
   background-color: #F5F3F0;
   position: relative;
   top: 1px;
   left: 1px;
   height: 5px;
   width: 5px;
   margin: 2px;
   transition: background-color 0.5s;
}
.burger:hover .burger-inner div {
   background-color: #FFF;
}

.search {
   overflow: visible;
}

.search_list_wrap {
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
}
.search_list {
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   position: relative;
   margin-left: 0;
   margin-right: 0;
   padding: 0;
   overflow: visible;
}

.search_list li {
   padding: 0 !important;
   width: 100%;
   height: 100%;
   text-align: left;
}
.search_list li:nth-child(n+2) {
   right: 0;
   position: absolute;
   top: 40px;
   max-width: 300px !important;
}
.search_list li:nth-child(n+3) {
   top: 80px;
}
.search_list li:nth-child(n+4) {
   top: 120px;
}
.search_list li:nth-child(n+5) {
   top: 160px;
}
.search_list li:nth-child(n+6) {
   top: 200px;
}
.search_list li:nth-child(n+7) {
   top: 240px;
}
.search_list li:nth-child(n+8) {
   top: 280px;
}

.search_list li:nth-child(1) {
   display: flex !important;
}

.search_list li:nth-child(n+2) {
   background-color: #FFF;
   display: block !important;
   margin: 0;
   box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
}

.search_list li:nth-child(n+2) a {
   color: #1b2146;
   display: block;
   height: fit-content;
}
.search_list li:nth-child(n+2) a > div {
   color: #1b2146;
}
.search_list li:nth-child(n+2) a:hover {
   background-color: #FFF;
   color: #0075C9;
}
.search_list li:nth-child(n+2) a:hover > div {
   background-color: #FFF;
   color: #0075C9;
}

.search_bar_wrap {
   width: 100%;
   display: flex;
   justify-content: flex-start;
   align-items: center;
}
.search_bar_wrap input {
   display: flex;
   flex-grow: 1;
}
ul.nav_main li.burger-outer {
   display: none;
}

.nav_mobile {
   color: #FFC20E;
   z-index: -1;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   margin: 0;
   padding: 0;
   transition: right 0.5s;
   display: flex;
   flex-direction: column;
   visibility: hidden;
}

.nav_mobile.visible {
   visibility: visible;
   z-index: 9;
}

.nav_mobile img {
   position: absolute;
   bottom: -50px;
   right: -100px;
   max-height: 60vh;
   opacity: 0;
}
.nav_mobile.visible img {
   transition: 0.75s opacity 0.5s;
   opacity: 1;
   z-index: 0;
}

.nav_mobile .wave {
   background-color: #0075C9;
   border: 1px solid #0075C9;
   height: 10%;
   width: 0;
   transition-duration: 1s;
   opacity: 0;
}
.nav_mobile.visible div  {
   transition-duration: 1s;
   opacity: 1;
   width: 100%;
}

.nav_mobile.visible div:nth-child(1) {
   transition-duration: 0.1s;
}
.nav_mobile.visible div:nth-child(2) {
   transition-duration: 0.2s;
}
.nav_mobile.visible div:nth-child(3) {
   transition-duration: 0.3s;
}
.nav_mobile.visible div:nth-child(4) {
   transition-duration: 0.4s;
}
.nav_mobile.visible div:nth-child(5) {
   transition-duration: 0.5s;
}
.nav_mobile.visible div:nth-child(6) {
   transition-duration: 0.6s;
}
.nav_mobile.visible div:nth-child(7) {
   transition-duration: 0.7s;
}
.nav_mobile.visible div:nth-child(8) {
   transition-duration: 0.8s;
}
.nav_mobile.visible div:nth-child(9) {
   transition-duration: 0.9s;
}
.nav_mobile.visible div:nth-child(10) {
   transition-duration: 1s;
}

.nav_mobile div:nth-child(1) {
   transition-duration: 0.1s;
}
.nav_mobile div:nth-child(2) {
   transition-duration: 0.2s;
}
.nav_mobile div:nth-child(3) {
   transition-duration: 0.3s;
}
.nav_mobile div:nth-child(4) {
   transition-duration: 0.4s;
}
.nav_mobile div:nth-child(5) {
   transition-duration: 0.5s;
}
.nav_mobile div:nth-child(6) {
   transition-duration: 0.6s;
}
.nav_mobile div:nth-child(7) {
   transition-duration: 0.7s;
}
.nav_mobile div:nth-child(8) {
   transition-duration: 0.8s;
}
.nav_mobile div:nth-child(9) {
   transition-duration: 0.9s;
}
.nav_mobile div:nth-child(10) {
   transition-duration: 1s;
}

.nav_mobile .office-info {
   position: absolute;
   width: 100%;
   max-width: 325px;
   top: 50px;
   height: 20vh;
}

.nav_mobile .office-info > div {
   position: relative;
   display: inline-block;
   vertical-align: top;
   color: #FFF;
   width: 50%;
   text-align: left;
   left: -50vw;
   opacity: 0;
   transition: 0.25s all 0s;
}

.nav_mobile.visible .office-info > div:nth-child(1) {
   left: 0;
   opacity: 1;
   transition: .5s all 0s;
}
.nav_mobile.visible .office-info > div:nth-child(2) {
   left: 0;
   opacity: 1;
   transition: .5s all 0.1s;
}

.nav_mobile .office-info > div > div {
   margin: 30px;
}

.nav_mobile .office-info > div > div h5 {
   margin: 0;
}

.nav_mobile .office-info > div > div p {
   margin: 0;
   font-size: 12px;
}
.nav_mobile .office-info > div > div a {
   color: #FFF;
   font-size: 12px;
}

.nav_mobile .middle {
   position: absolute;
   top: 30%;
   height: 60%;
   padding: 0;
   margin: 0;
   width: 100%;
}

.nav_mobile .middle li {
   display: flex;
   justify-content: flex-start;
   align-items: flex-start;
   padding-left: 0;
   opacity: 0;
   transition: all 0.25s;
   transition-delay: 0;
   position: relative;
   left: -100vw;
}

.nav_mobile .middle li a {
   display: block;
   width: 100%;
   padding: 10px;
   padding-left: 30px;
   font-size: 1.5em;
   color: #FFF;
   text-align: left;
   text-decoration: none;
   font-weight: 800;
   letter-spacing: -1px;
}

.nav_mobile .middle li a:hover {
   opacity: 0.5;
}

@media only screen and (min-width: 700px) {
   .nav_mobile .middle {
      position: absolute;
      top: 25vh;
   }
   .nav_mobile .middle li a {
      font-size: 2.25em;
      line-height: 1em;
   }
   .nav_mobile img {
      height: 65vh;
   }
}

@media only screen and (max-height: 700px) {
   .nav_mobile .middle li a {
      font-size: 1.5em;
      line-height: 1em;
   }
}

.nav_mobile .bottom {
   position: absolute;
   bottom: 15px;
   height: 15vh;
   padding: 0;
   margin: 0;
   width: 100%;
}

.nav_mobile .bottom li {
   display: flex;
   justify-content: flex-start;
   align-items: flex-start;
   padding-left: 0;
   opacity: 0;
   transition: all 0.25s;
   transition-delay: 0;
   position: relative;
   left: -100vw;
}

.nav_mobile.visible .bottom li {
   left: 30px;
   margin-top: 5px;
}

.nav_mobile .bottom li a {
   text-decoration: none;
   color: #FFF;
   font-size: 12px;
   cursor: pointer;
}
.nav_mobile .bottom li:nth-last-child(1) a {
   font-weight: 600;
}

.nav_mobile.visible ul li {
   opacity: 1;
   transition: all 0.5s;
   left: 0;
}

.nav_mobile.visible .middle li:nth-child(1) {
   transition-delay: 0.2s;
}
.nav_mobile.visible .middle li:nth-child(2) {
   transition-delay: 0.3s;
}
.nav_mobile.visible .middle li:nth-child(3) {
   transition-delay: 0.4s;
}
.nav_mobile.visible .middle li:nth-child(4) {
   transition-delay: 0.5s;
}
.nav_mobile.visible .middle li:nth-child(5) {
   transition-delay: 0.6s;
}
.nav_mobile.visible .middle li:nth-child(6) {
   transition-delay: 0.7s;
}
.nav_mobile.visible .middle li:nth-child(7) {
   transition-delay: 0.8s;
}
.nav_mobile.visible .bottom li:nth-child(1) {
   transition-delay: 0.7s;
}
.nav_mobile.visible .bottom li:nth-child(2) {
   transition-delay: 0.8s;
}
.nav_mobile.visible .bottom li:nth-child(3) {
   transition-delay: 0.9s;
}
.nav_mobile.visible .bottom li:nth-child(4) {
   transition-delay: 1s;
}



.nav_mobile li button {
   display: block !important;
   width: 100% !important;
   padding: 20px;
   background-color: #0075C9;
   border: none;
   color: #FFF;
   font-size: 3em;
   font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   font-weight: 800;
   text-align: left;
   text-decoration: none;
}

.nav_mobile li a:hover {
   color: #FFF;
}


.nav_mobile li button:hover {
   color: #FFF;
}



.nav_main li.services-dropdown {
   position: relative;
}

nav .services {
   display: none;
   z-index: 1; 
   position: fixed;
   top: 50px;
   right: 0px;
   transition: all 0.5s;
   height: 50px;
   background: #FFF;
}
nav .services.visible {
   display: block;
}

nav .services ul {
   height: 50px;
   list-style: none;
   padding: 0;
   margin: 0;
   display: flex;
   box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
}
nav .services ul li {
   display: block;
   margin: 0;
   
}
nav .services ul li:nth-child(2) {
   margin: 0;
}
nav .services ul li a {
   position: relative;
   font-size: 0.85em;
   padding: 0 10px;
   height: 50px;
   font-weight: 600;
   text-decoration: none;
   display: flex;
   color: #0075C9;
}

nav .services ul li a:nth-child(1) {
   padding: 0 10px;
}

.services-toggle {
   display: flex;
   justify-content: center;
   align-items: center;
   width: fit-content;
   white-space: nowrap;
}

nav .services ul li a:hover, nav .services ul li a:nth-child(1):hover {
   color: #0075C9;
   background-color: #F5F3F0;
}
nav .services.visible ul {
   visibility: visible;
   transition-delay: 0.1s;
}

.locations {
   z-index: 1;
   color: #1A1F39;
   background-color: #F5F3F0;
   position: absolute;
   top: 51px;
   width: fit-content;
   right: -1000px;
   transition: all 0.5s;
   padding: 10px;
   border-bottom-left-radius: 3px;
   border-top: none;
   padding-bottom: 1em;
}

.locations.visible {
   right: 0;
}

.locations > div {
   max-width: 200px;
   display: inline-block;
   
}

.locations h5 {
   padding-left: 0.5em;
   margin-block-start: 1em;
   margin-block-end: 0.5em;
   margin-top: 1em;
   margin-bottom: 0.5em;
}

.locations ul {
   margin: 0;
   padding: 0;
   list-style: none;
   display: flex;
   flex-direction: column;
}

.locations ul li {
   display: block !important;
}
.locations ul li a {
   display: block;
   color: #1A1F39;
   font-size: 0.75em;
   padding: 0.5em;
   text-decoration: none;
   height: 100%;
}

.locations ul li a:hover {
   text-decoration: underline;
}

/* @media only screen and (min-width: 960px) and (max-width: 1100px) {
   .nav_main li:nth-child(3) {
      display: none;
   }
} */


@media only screen and (max-width: 1195px) {
   .nav_main li.widescreen {
      display: none;
   }
}
@media only screen and (min-width: 1195px) {
   .nav_main li.services-dropdown {
      display: none;
   }
}


@media only screen and (min-width: 875px) {
   .nav_mobile.visible {
      display: none;
   }
}

@media only screen and (max-width: 875px) {
   .search-bar {
      max-width: 150px;
   }
   .search_bar_wrap {
      justify-content: flex-end;
   }
   .services.visible {
      display: none;
   }
   nav ul li:nth-child(n+3) {
      display: none;
   }
   .burger {
      display: flex;
   }

   .nav_main li:nth-child(2) {
      align-items: flex-end;
      padding-left: 25px;
      padding-right: 0;
      max-width: 1000px;
   }

   .nav_main {
      justify-content: space-between;
   }
   
   ul.nav_main li.burger-outer {
      display: block;
      padding-left: 0;
   }
 }

@media only screen and (max-width: 375px) {
   .search-bar {
      max-width: 100px;
   }
}