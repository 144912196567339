.page-component {
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    align-items: center;
}

.page-component.light { background: #F5F3EF }
.page-component.blue { background: #015FA6; }
.page-component.dark-blue { background: #0d122c; }

@media only screen and (max-width: 600px) {
    .page-component {
        padding-left: 20px;
        padding-right: 20px;
    }
}