.privacy-policy {
    margin: 3em 0em;
    text-align: left;
    font-size: 12px;
    background-color: #0d122c;
    color: #FFF;
}

.privacy-policy h3 {
    font-size: 24px;
}
.privacy-policy h5 {
    font-size: 14px;
}