.missing-page {
    background-color: #F5F3F0;
}

.missing-message {
    padding: 100px 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.missing-message .text {
    display: flex;
    text-align: right;
    align-items: flex-end;
    flex-direction: column;
    color: #1b2a39;
    max-width: 50%;
}
.missing-message img {
    height: 300px;
    width: 360px;
}

.missing-message h1 {
    letter-spacing: -2px;
    font-size: 2.5em;
    line-height: 0.9em;
}
.missing-message p {
    margin-top: 0;
}

@media only screen and (max-width: 800px) {
    .missing-message {
        flex-direction: column;
    }
    .missing-message .text {
        max-width: 100%;
        align-items: center;
        text-align: center;
    }
    .missing-message img {
        height: 300px;
        
    }
 }