.affiliated .header {
    height: 500px;
    position: relative;
}

.affiliated .header img {
    position: absolute;
    top: 20%;
    right: 5%;
    height: 300px;
}

.affiliated section {
    width: 100%;
    color: #1b2a39;
    background-color: #FFF;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    display: block;
}

.affiliated section .content {
    height: 100%;
    position: relative;
    padding-left: 5%;
    padding-right: 5%;
}


.affiliated h1 {
    margin: 0;
    line-height: 0.9em;
    font-size: 2.5em;
    letter-spacing: -2px;
}

.affiliated .header .content .text {
    position: absolute;
    width: 500px;
    height: fit-content;
    z-index: 3;
    left: 10%;
    text-align: left;
    top: 0;
    bottom: 20%;
    margin: auto;
    transition: all 0.5s;
}

.affiliated .header .text h1 span {
    color: #0075C9;
}
.affiliated .header .text h5 {
    opacity: 0.5;
    letter-spacing: 2px;
    margin-bottom: 0.75em;
    margin-top: 0.75em;
}
.affiliated .header .text p {
    width: 410px;
}
.affiliated .affiliate.right {
    text-align: right;
}

.affiliated .affiliate.left {
    text-align: left;
}

.affiliated .affiliate .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.affiliated .affiliate .content .divider {
    width: 40%;
}

.affiliated .product-image {
    height: auto;
    width: 100%;
}
.affiliated .left .product-image {
    float: right;
}

@media only screen and (max-width: 900px) {
    .affiliated .header .content .text {
        position: absolute;
        top: 30%;
        bottom: 0;
        left: 0;
        text-align: center;
        width: 90%;
        margin: auto 20px;
    }
    .affiliated .affiliate.left {
        text-align: center;
    }
    .affiliated .affiliate.right {
        text-align: center;
    }
    .affiliated .affiliate .content {
        flex-direction: column;
        justify-content: center;
    }
    .affiliated .affiliate:nth-child(odd) .content {
        flex-direction: column-reverse;
    }
    .affiliated:first-child .affiliate .content {
        flex-direction: column;
    }
    .affiliated .affiliate.stake .content {
        flex-direction: column-reverse;
    }

    .affiliated .affiliate .content .divider {
        width: 100%;
        display: inline-block;
        margin: 20px;
        text-align: center;
    }
    .affiliated .affiliate .content .divider iframe {
        float: none !important;
        max-width: 100%;
    }
    .affiliated .product-image {
        height: 200px;
        width: auto !important;
        float: none !important;
    }
}



.affiliated section:nth-child(odd) .content .divider iframe {
    float: left;
}
.affiliated section:nth-child(even) .content .divider iframe {
    float: right;
}

.affiliated section:nth-child(even) {
    background-color: #F4F5F7;
}

.affiliated .cta {
    display: inline-block;
    cursor: pointer;
    background-color: transparent;
    color: #0075C9;
    font-weight: 900;
    text-decoration: none;
    font-size: 1em;
    padding: 0.8em;
    padding-left: 3em;
    padding-right: 3em;
    margin-top: 1em;
    border: 2px solid #0075C9;
    position: relative;
    overflow: hidden;
    transition: all 0.5s;
    margin: 10px;
}
.affiliated .cta.leftmost {
    margin-left: 0;
}
.affiliated .cta.rightmost {
    margin-right: 0;
}
.affiliated .cta.white {
    color: #FFF;
    border: 2px solid #FFF;
}

.affiliated .cta.unpad {
    padding-left: 0.8em;
    padding-right: 0.8em;
}

.affiliated .cta p {
    position: relative;
    z-index: 3;
    
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin: 0 !important;
}

.affiliated .cta:hover p {
    color: #FFF;
    transition: color 0.25s;
}

.affiliated .cta.white:hover p {
    color: #0d122c;
}

.affiliated .cta div {
    position: absolute;
    top: 0;
    left: -20px;
    background-color: #0075C9;
    height: 100%;
    width: 0px;
    z-index: 1;
    transition: all 0.5s;
    transform: skew(.4rad);
}

.affiliated .cta.white div {
    background-color: #FFF;
}

.affiliated .cta:hover div {
    width: 100%;
    transition: all 0.5s;
    transform: skew(0);
    left: 0px;
}

.affiliated .cta:hover {
    transition: all 0.5s;
}

.app-store-link {
    display: inline-block;
    position: relative;
    top: 15px;
}

.app-store-link {
    margin: 0 4px;
}


.affiliate-logo {
    height: 50px;
    width: auto;
}

/* MOBILE */

@media only screen and (max-width: 920px) {

    .affiliated .header {
        height: 700px;
        position: relative;
    }  
    .affiliated .header .page-header {
        top: 150px;
        max-width: 100%;
        text-align: center;
    }      
    .affiliated .header img {
        position: absolute;
        top: 100px;
        right: 0;
        left: 0;
        margin: auto;
        height: 200px;
    }

    .affiliated .header h1 {
        font-size: 2em;
    }
    .affiliated .space-mobile {
        padding: 40px 0;
    }
}