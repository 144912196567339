.selected .selection-timer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: rgb(228, 228, 228);
}

.selection-timer > div {
    width: 0%;
    transition: width 10s linear;
}

.selected.noInterval .selection-timer > div {
    transition: none;
}

.selected .selection-timer > div {
    background-color: #0075C9;
    width: 100%;
    height: 5px;
    position: absolute;
    top: 0;
    left: 0;
}

.list-component {
    height: fit-content;
    min-height: 90vh;
    background-color: #f5f3f0;
    color: #1A1F39;
}

.list-component h1 span {
    color: #0075C9;
}

.list-component .content {
    width: 90%;
    margin-left: 5%;
    height: 100%;
}

.list-component .content .divider {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    height: 100%;
}

.list-component .content .divider:nth-child(1){
    width: 35%;
}
.list-component.reversed .content .divider:nth-child(1) {
    width: 55%;
}
.list-component .content .divider:nth-child(2) {
    width: 65%;
}
.list-component.reversed .content .divider:nth-child(2) {
    width: 45%;
}

.list-component .content .divider > div {
    text-align: left;
    display: flex;
    flex-direction: column;
    margin: 25px;
}
.list-component .content .divider:nth-child(1) > div {
    margin-left: 0px;
}
.list-component .content .divider:nth-child(2) > div {
    margin-right: 0px;
}

.list-component .content .divider .services-illustration {
    background-color: #0d122c;
    height: 20%;
}

.list-component .content .divider .services-summary h5 {
    opacity: 0.5;
    letter-spacing: 2px;
    margin-bottom: 0.75em;
    margin-top: 0.75em;
}

.list-component .content .divider .services-summary p {
    opacity: 0.75;
}

.list-component .content .divider .collapsible-list {
    height: 100%;
}

.list-component .collapsible-list > ul {
    list-style: none;
    padding: 0;
    height: 100%;
}

.list-component .collapsible-list > ul > li {
    background-color: #FFF;
    height: 75px;
    max-height: 75px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
    transition: all 0.75s;
}

.list-component .collapsible-list ul li button {
    background-color: #FFF;
    color: #0075C9;
    outline: none;
    border: none;
    width: 100%;
    font-size: 1.2em;
    line-height: 0.6em;
    height: 75px;
    text-align: left;
    cursor: pointer;
    
}
.list-component .collapsible-list ul li button.headline-btn {
    display: flex;
}

.list-component .collapsible-list ul li h3 {
    display: inline-block;
    font-size: 1.5em;
    line-height: 1em;
    margin-top: 0.75em;
}
.list-component .collapsible-list ul li h3.index {
    width: 30px;
    left: -10px;
    position: relative;
}
.list-component .collapsible-list ul li h3.index {
    width: 30px;
}
.list-component .collapsible-list ul li h3.headline {
    flex-grow: 1;
    padding-left: 10px;
}
.list-component .collapsible-list ul li h3 span {
    padding-right: 15px;
}

.list-component .collapsible-list .list-item-info ul {
    list-style: none;
    padding: 0;
}
.list-component .collapsible-list .list-item-info ul li {
    opacity: 0.75;
}
.list-component .collapsible-list .list-item-info ul li div {
    display: inline-block;
    height: 2px;
    width: 10px;
    margin-bottom: 4px;
    margin-right: 4px;
    background-color: #005FA6;
}


.list-component .collapsible-list ul li.selected {
    transition: all 0.75s;
    height: fit-content;
    max-height: inherit;
}

.list-component .collapsible-list ul li.wrap {
    height: 100px;
    max-height: 100px;
}


.list-component .collapsible-list ul li .list-item-info {
    height: fit-content;
    background-color: #FFF;
    color: #1A1F39;
    padding-left: 50px;
    padding-top: 10px;
}
.list-component .collapsible-list ul li .learn-more {
    margin-left: 50px;
    width: 200px;
    cursor: pointer;
    background-color: #FFF;
    color: #0075C9;
    font-weight: 900;
    height: 50px;
    text-decoration: none;
    font-size: 1em;
    margin-top: 1em;
    margin-bottom: 20px;
    border: 2px solid #0075C9;
    position: relative;
    overflow: hidden;
    transition: all 0.5s;
}

.list-component .collapsible-list ul li .learn-more p {
    position: relative;
    z-index: 3;
    width: 100%;
    text-align: center;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin: 0 !important;
}

.list-component .collapsible-list ul li .learn-more:hover p {
    color: #FFF;
    transition: color 0.25s;
}

.list-component .collapsible-list ul li .learn-more div {
    position: absolute;
    top: 0;
    left: -20px;
    background-color: #0075C9;
    height: 100%;
    width: 0px;
    z-index: 1;
    transition: all 0.5s;
    transform: skew(.4rad);
}

.list-component .collapsible-list ul li .learn-more:hover div {
    width: 100%;
    transition: all 0.5s;
    transform: skew(0);
    left: 0px;
}

.list-component .collapsible-list ul li .learn-more:hover {
    transition: all 0.5s;
}

@media only screen and (max-width: 700px) {
    .list-component {
        height: fit-content;
        min-height: 100vh;
    }
    .list-component .content .divider > div {
        text-align: left;
        display: flex;
        flex-direction: column;
        margin: 25px 0px;
    }
    .list-component .content .divider {
        width: 100% !important;
        height: fit-content;
    }
    .list-component .content .divider .services-illustration {
        display: none;
        height: 0;
    }
    .list-component .content .divider .collapsible-list {
        margin-top: -20px;
    }
}