.contact-section {
    color: #FFF;
}

.contact-section h1 {
    margin: 0;
    line-height: 0.9em;
    font-size: 2.5em;
    letter-spacing: -2px;
}

.contact-section form h1 {
    padding-top: 1em;
}

.contact-section .inputs {
    width: 100%;
    padding-top: 2em;
    padding-bottom: 2em;
}

.contact-section .inputs .input-field {
    width: 100%;
}

.contact-section input,
.contact-section input:-internal-autofill-selected,
.contact-section input:-webkit-autofill,
.contact-section input:-webkit-autofill:hover,
.contact-section input:-webkit-autofill:focus
{
    display: inline-block;
    -webkit-text-fill-color: #FFF;
    -webkit-box-shadow: 0 0 0px 1000px #015FA6 inset;
    background-color: #015FA6;
    outline: none;
    border: none;
    width: fit-content;
    color: #FFF;
    font-size: 2em;
    margin-left: 10px;
    font-weight: 800;
    
}
.contact-section .inputs .input-field > input::placeholder {
    color: #FFF;
    font-weight: 800;
    opacity: 0.5;
}

.contact-section .inputs .input-field select {
    background: none;
    border: none;
    color: #FFF;
    font-weight: 900;
    text-decoration: none;
    font-size: 2em;
    padding: 0.8em;
    text-decoration: underline;
}

.contact-section .inputs .input-field button {
    cursor: pointer;
    background-color: #015FA6;
    color: #FFF;
    font-weight: 900;
    text-decoration: none;
    font-size: 1.2em;
    padding: 0.8em;
    padding-left: 3em;
    padding-right: 3em;
    margin-top: 2.5em;
    border: 2px solid #FFF;
    position: relative;
    overflow: hidden;
}

.contact-section .inputs .input-field button p {
    position: relative;
    z-index: 2;
    margin: 0;
}

.contact-section .inputs .input-field button div {
    position: absolute;
    top: 0;
    left: -20px;
    background-color: #FFF;
    height: 100%;
    width: 0px;
    z-index: 1;
    transition: all 0.5s;
    transform: skew(.4rad);
}
.contact-section .inputs .input-field button:hover p {
    color: #091F42;
}

.contact-section .inputs .input-field button:hover div {
    width: 100%;
    transition: all 0.5s;
    transform: skew(0);
    left: 0px;
}

.contact-section .inputs .input-field > p {
    display: inline-block;
    font-size: 2em;
    margin: 0em;
    margin-bottom: 0.5em;
}

.contact-section .contact-topics ul {
    z-index: 3;
    position: absolute;
    left: 275px;
    background-color: #015FA6;
    font-size: 1.75em;
    list-style: none;
    margin: 0;
    padding: 10px 10px;
    border: 2px solid #FFF;
}
.contact-section .inputs .contact-topics button {
    margin: 0;
    border: none;
    padding: 0.5em 1em;
    font-weight: 300;
    padding-top: 0;
}

.contact-section .inputs .contact-topics .topics-toggle {
    margin-top: 0;
    outline: none;
    font-weight: 900;
    font-size: 2em;
    padding-left: 7px;
}
.contact-section .inputs .contact-topics .topics-toggle svg {
    margin-left: 10px;
}
.contact-section .inputs .submit .submit-btn {
    font-size: 2em;
    padding: 0.5em 2em;
    margin-top: 1em;
}

@media only screen and (max-width: 600px) {
    .contact-section {
        height: 600px !important;
    }
    .contact-section .inputs {
        padding-top: 2em;
    }
    .contact-section .inputs .contact-topics .topics-toggle {
        font-size: 1.5em;
        padding-left: 7px;
    }
    .contact-section .contact-topics ul {
        z-index: 3;
        position: absolute;
        left: 25px;
        margin-top: 50px;
    }
    .contact-section .inputs .input-field > select {
        display: block;
        margin: 0;
        font-size: 1.5em;
        margin-left: -8px;
    }
    .contact-section .inputs .input-field > input {
        display: block;
        margin: 0;
        font-size: 1.5em;
    }
    .contact-section .inputs .input-field > p {
        font-size: 1.5em;
        padding-bottom: 0px;
        padding-top: 10px;
    }
    .contact-section .inputs .input-field button {
        font-size: 1em;
    }
}