.affiliated-section {
    height: auto;
    margin: 50px 0;
    padding: 20px 0;
    width: 100%;
    max-width: 100%;
    background: #FFF;
    color: #0075C9;
    color: #0d122c;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    z-index: 2;
    box-shadow: 0px 10px 15px rgb(0 0 0 / 5%);
    position: relative;
}

.affiliated-section .title-underline {
    height: 4px;
    width: 80px;
    background: #0075C9;
    /* position: absolute; */
    left: 0;
    right: 0;
    margin: 0 auto;
    margin-bottom: 30px;
}
.affiliated-section h3 {
    max-width: 80%;
    font-size: 24px;
    font-weight: 700;
}
.affiliated-section ul {
    width: 90%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    margin: 20px 0;
    list-style: none;
    display: flex;
    justify-content: space-evenly;
}

.affiliated-section ul li {
    max-width: 25%;
    display: flex;
    justify-content: center;
    padding: 10px 0;
}
.affiliated-section ul li img {
    height: 30px;
}

.affiliated-section .meet-our-affiliates {
    width: 220px;
    cursor: pointer;
    background: #FFF;
    color: #0075C9;
    font-weight: 900;
    height: 50px;
    text-decoration: none;
    font-size: 1em;
    margin-top: 1em;
    margin-bottom: 20px;
    border: 2px solid #0075C9;
    position: relative;
    overflow: hidden;
    transition: all 0.5s;
    display: flex;
    align-items: center;
}

.affiliated-section .meet-our-affiliates p {
    position: relative;
    z-index: 3;
    width: 100%;
    text-align: center;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin: 0 !important;
}

.affiliated-section .meet-our-affiliates:hover p {
    color: #FFF;
    transition: color 0.25s;
}

.affiliated-section .meet-our-affiliates div {
    position: absolute;
    top: 0;
    left: -20px;
    background-color: #0075C9;
    height: 100%;
    width: 0px;
    z-index: 1;
    transition: all 0.5s;
    transform: skew(.4rad);
}

.affiliated-section .meet-our-affiliates:hover div {
    width: 100%;
    transition: all 0.5s;
    transform: skew(0);
    left: 0px;
}

.affiliated-section .meet-our-affiliates:hover {
    transition: all 0.5s;
}

@media only screen and (max-width: 960px) {
    .affiliated-section img {
        transform: scale(0.9);
    }
}

@media only screen and (max-width: 700px) {

    .affiliated-section {
        height: 600px;
    }
    
    .affiliated-section ul {
        height: 350px;
        flex-direction: column-reverse;
    }
    
    .affiliated-section ul li {
        max-width: 100%;
    }
}