@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,800&display=swap);
.home {
    position: relative;
    overflow: hidden;
}
.home .announcement {
    background: #1A1F39;
    font-size: 12px;
    color: #FFF;
}
.home .announcement a {
    font-weight: 600;
    color: #FFF;
}

.home .announcement > div {
    padding: 10px;
}

.home section .page-header {
    z-index: 1;
}
.home section .page-header h1 {
    /* color: #FFF; */
}
.home section .page-header h1 span {
    color: #0075C9;
    /* color: #FFF; */
}
.home section .content {
    height: 100%;
    position: relative;
    z-index: 0;
}
.home section.list-component.services .content {
    display: flex;
}

.home h1 {
    margin: 0;
    line-height: 0.9em;
    font-size: 2.5em;
    letter-spacing: -2px;
}

/* HEADER SECTION */

.home .header img {
    width: 100%;
}

.home .header .content > div {
    width: 100%;
}

.home .header {
    background: rgb(240,252,255);
    background: linear-gradient(30deg,#F0FCFF 0%,  #8CD1F1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f0fcff",endColorstr="#008ad0",GradientType=1);
    /* background: #FFF; */
    color: #1A1F39;
    overflow: hidden;
}
.home .header .content {
    position: relative;
} 

/* @keyframes spinningWheel {
    0% {
        transform: rotate(-8deg);
    }
    50% {
        transform: rotate(8deg);
    }
    100% {
        transform: rotate(-8deg);
    }
} */
.home .header .content .text {
    position: absolute;
    width: 500px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    z-index: 3;
    left: 10%;
    text-align: left;
    top: 0;
    bottom: 20%;
    margin: auto;
    transition: all 0.5s;
}
.home .header .page-header.blue > p {
    /* color: #F0FCFF; */
}
.home .header .content .text p {
    /* color: #F0FCFF; */
}


.home .header .ship img {
    width: auto;
    height: 27.5vw;
    animation: rockTheBoat 10s infinite;
}

@keyframes rockTheBoat {
    0% {
        transform: rotate(-2deg)
    }
    25% {
        transform: rotate(2deg)
    }
    50% {
        transform: rotate(-3deg)
    }
    75% {
        transform: rotate(1deg)
    }
    100% {
        transform: rotate(-2deg)
    }
}

.home .header .blue-spacer {
    background-color: #f5f3f0;
    display: block;
    height: 65vw;
}

/* SERVICES SECTION */
.home section.list-component.services {
    position: relative;
    
}
.home section.list-component.services .content {
    z-index: 2;
}
.services-summary .heading {
    display: flex;
}
.home .spinning-wheel {
    height: 550px;
    position: absolute;
    bottom: -150px;
    right: -200px;
    z-index: 1;
}
.home .spinning-wheel img {
    height: 550px;
    animation: spinningWheel infinite 5s linear;
    opacity: 0.15;
}

/* DIFFERENTIATOR SECTION */

.home .differentiator {
    background-color: #FFF;
    color: #1A1F39;
}

.home .differentiator .content {
    display: flex;
    justify-content: center;
    align-items: center;
}


/* MOBILE */

@media only screen and (max-width: 900px) {
    .home section.list-component.services .content {
        flex-direction: column-reverse;
    }

    .home section.list-component.services .content .divider {
        width: 100%;
        text-align: center;
    }
}
@media only screen and (max-width: 600px) {
    /* .home section {
        height: 80% !important; 
    }  */
    .home h1 {
        font-size: 2em !important;
    }


    .home .header .content .text {
        width: 80%;
        left: 10%;
        text-align: center;
        bottom: 25%;
    }

    .home .header .text p {
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        margin: auto;
        margin-top: 20px;
        text-align: center;
        width: 100%;
    }
}



.page-component {
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    align-items: center;
}

.page-component.light { background: #F5F3EF }
.page-component.blue { background: #015FA6; }
.page-component.dark-blue { background: #0d122c; }

@media only screen and (max-width: 600px) {
    .page-component {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.big-button {
    cursor: pointer;
    font-weight: 900;
    text-decoration: none;
    font-size: 1.2em;
    padding: 0.8em;
    padding-left: 3em;
    padding-right: 3em;
    margin-top: 1em;
    position: relative;
    overflow: hidden;
    transition: all 0.5s;
}

.big-button p {
    position: relative;
    z-index: 3;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin: 0 !important;
}
.big-button:hover p { transition: color 0.25s;}

.big-button div {
    position: absolute;
    top: 0;
    left: -20px;
    height: 100%;
    width: 0px;
    z-index: 1;
    transition: all 0.5s;
    transform: skew(.4rad);
}

.big-button:hover div {
    width: 100%;
    transition: all 0.5s;
    transform: skew(0);
    left: 0px;
}

.big-button:hover { transition: all 0.5s; }
.big-button.white {
    background-color: #0075C9;
    color: #FFF;
    border: 2px solid #0075C9;
}
.big-button.blue {
    background-color: #FFF;
    color: #0075C9;
    border: 2px solid #0075C9;
}
.big-button.beige {
    background-color: #F5F3F0;
}
.big-button.green {
    background-color: #01A63B !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.big-button.green:hover div {
    width: 0 !important;
}
.big-button.green:hover p {
    color: #FFF !important;
}
.big-button.green p {
    position: relative;
    right: 10px;
}
.big-button.green svg {
    transform: scale(0.3);
    position: absolute;
    left: 140px;
}

.big-button.blue div { 
    background-color: #0075C9;
    /* box-shadow: 0 10px 36px rgba(0,0,50,.01); */
    border-radius: 3px;
}
.big-button.white div { background-color: #FFF }
.big-button.blue:hover p { color: #FFF; }
.big-button.white:hover p { color: #0075C9; }
.big-button.white:hover { 
    border: 2px solid #FFF;
    box-shadow: 0 10px 24px rgba(0,0,50,.075);
    border-radius: 3px;
}
.contact-section {
    color: #FFF;
}

.contact-section h1 {
    margin: 0;
    line-height: 0.9em;
    font-size: 2.5em;
    letter-spacing: -2px;
}

.contact-section form h1 {
    padding-top: 1em;
}

.contact-section .inputs {
    width: 100%;
    padding-top: 2em;
    padding-bottom: 2em;
}

.contact-section .inputs .input-field {
    width: 100%;
}

.contact-section input,
.contact-section input:-internal-autofill-selected,
.contact-section input:-webkit-autofill,
.contact-section input:-webkit-autofill:hover,
.contact-section input:-webkit-autofill:focus
{
    display: inline-block;
    -webkit-text-fill-color: #FFF;
    -webkit-box-shadow: 0 0 0px 1000px #015FA6 inset;
    background-color: #015FA6;
    outline: none;
    border: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: #FFF;
    font-size: 2em;
    margin-left: 10px;
    font-weight: 800;
    
}
.contact-section .inputs .input-field > input::placeholder {
    color: #FFF;
    font-weight: 800;
    opacity: 0.5;
}

.contact-section .inputs .input-field select {
    background: none;
    border: none;
    color: #FFF;
    font-weight: 900;
    text-decoration: none;
    font-size: 2em;
    padding: 0.8em;
    text-decoration: underline;
}

.contact-section .inputs .input-field button {
    cursor: pointer;
    background-color: #015FA6;
    color: #FFF;
    font-weight: 900;
    text-decoration: none;
    font-size: 1.2em;
    padding: 0.8em;
    padding-left: 3em;
    padding-right: 3em;
    margin-top: 2.5em;
    border: 2px solid #FFF;
    position: relative;
    overflow: hidden;
}

.contact-section .inputs .input-field button p {
    position: relative;
    z-index: 2;
    margin: 0;
}

.contact-section .inputs .input-field button div {
    position: absolute;
    top: 0;
    left: -20px;
    background-color: #FFF;
    height: 100%;
    width: 0px;
    z-index: 1;
    transition: all 0.5s;
    transform: skew(.4rad);
}
.contact-section .inputs .input-field button:hover p {
    color: #091F42;
}

.contact-section .inputs .input-field button:hover div {
    width: 100%;
    transition: all 0.5s;
    transform: skew(0);
    left: 0px;
}

.contact-section .inputs .input-field > p {
    display: inline-block;
    font-size: 2em;
    margin: 0em;
    margin-bottom: 0.5em;
}

.contact-section .contact-topics ul {
    z-index: 3;
    position: absolute;
    left: 275px;
    background-color: #015FA6;
    font-size: 1.75em;
    list-style: none;
    margin: 0;
    padding: 10px 10px;
    border: 2px solid #FFF;
}
.contact-section .inputs .contact-topics button {
    margin: 0;
    border: none;
    padding: 0.5em 1em;
    font-weight: 300;
    padding-top: 0;
}

.contact-section .inputs .contact-topics .topics-toggle {
    margin-top: 0;
    outline: none;
    font-weight: 900;
    font-size: 2em;
    padding-left: 7px;
}
.contact-section .inputs .contact-topics .topics-toggle svg {
    margin-left: 10px;
}
.contact-section .inputs .submit .submit-btn {
    font-size: 2em;
    padding: 0.5em 2em;
    margin-top: 1em;
}

@media only screen and (max-width: 600px) {
    .contact-section {
        height: 600px !important;
    }
    .contact-section .inputs {
        padding-top: 2em;
    }
    .contact-section .inputs .contact-topics .topics-toggle {
        font-size: 1.5em;
        padding-left: 7px;
    }
    .contact-section .contact-topics ul {
        z-index: 3;
        position: absolute;
        left: 25px;
        margin-top: 50px;
    }
    .contact-section .inputs .input-field > select {
        display: block;
        margin: 0;
        font-size: 1.5em;
        margin-left: -8px;
    }
    .contact-section .inputs .input-field > input {
        display: block;
        margin: 0;
        font-size: 1.5em;
    }
    .contact-section .inputs .input-field > p {
        font-size: 1.5em;
        padding-bottom: 0px;
        padding-top: 10px;
    }
    .contact-section .inputs .input-field button {
        font-size: 1em;
    }
}
.global {
    display: block;
    color: #FFF;
    background-color: #0d122c;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-top: 5%;
    padding-left: 5%;
    padding-right: 5%;
    border-bottom-left-radius: 3px;
    border-top: none;
    padding-bottom: 1em;
    position: relative;
    text-align: left;
    min-width: 90%;
    overflow: hidden;
}
.global .map {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 0;
    height: 80%;
    max-width: 100%;
}

.global > div {
    position: relative;
    display: inline-block;
    vertical-align: top;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; 
    width: 20%;
    padding-right: 5%;
    z-index: 1;
}

@media only screen and (max-width: 600px) {
    .global > div {
        display: inline-block;
        vertical-align: top;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content; 
        width: 20%;
        padding-right: 4%;
        min-width: 200px;
    }
}

.global h5 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: 2px solid #FFF;
}

.global ul {
    list-style: none;
    padding: 0;
    text-align: left;
}

.global ul li a {
    text-decoration: none;
    color: #FFF;
    opacity: 0.75;
    font-size: 0.75em;
}

.global ul li a:hover {
    opacity: 1;
}

footer ul {
    display: flex;
    margin: 0;
    padding: 0;
}
footer #logo {
    padding-top: 10px;
}

@media (max-width: 750px) {
    .footer-container {
        height: auto;
    }
    footer ul { 
        flex-direction: column;
        padding-bottom: 50px;
    }
    footer ul li:nth-child(2) {
        padding-top: 0.25em;
        padding-bottom: 0.25em;
    }
    footer ul li:nth-child(2) h5 {
        margin-block-start: 0.67em;
        margin-block-end: 1.67em;
    }
    footer ul li:nth-child(3) {
        padding-bottom: 2.5em;
    }
}
@media (min-width: 900px) {
    .footer-container {
        height:300px;
    }
}

footer ul li {
    list-style: none;
    text-align: left;
    padding: 1em;
    padding-left: 0;
}

footer ul li p {
    font-size: 0.7em;
}

footer .email-link {
    color: #0075C9;
}

.important-links {
    width: 100%;
    min-height: 50px;
    color: #FFF;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.75em;
}

.important-links a {
    text-decoration: none;
    color: #FFF;
    padding-right: 1em;
}
.important-links a:active, .important-links a:focus {
    font-weight: 600;
    outline: none;
 }

.important-links p {
    text-align: right;
    flex-grow: 1;
    margin: 0;
}
.affiliated-section {
    height: auto;
    margin: 50px 0;
    padding: 20px 0;
    width: 100%;
    max-width: 100%;
    background: #FFF;
    color: #0075C9;
    color: #0d122c;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    z-index: 2;
    box-shadow: 0px 10px 15px rgb(0 0 0 / 5%);
    position: relative;
}

.affiliated-section .title-underline {
    height: 4px;
    width: 80px;
    background: #0075C9;
    /* position: absolute; */
    left: 0;
    right: 0;
    margin: 0 auto;
    margin-bottom: 30px;
}
.affiliated-section h3 {
    max-width: 80%;
    font-size: 24px;
    font-weight: 700;
}
.affiliated-section ul {
    width: 90%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    margin: 20px 0;
    list-style: none;
    display: flex;
    justify-content: space-evenly;
}

.affiliated-section ul li {
    max-width: 25%;
    display: flex;
    justify-content: center;
    padding: 10px 0;
}
.affiliated-section ul li img {
    height: 30px;
}

.affiliated-section .meet-our-affiliates {
    width: 220px;
    cursor: pointer;
    background: #FFF;
    color: #0075C9;
    font-weight: 900;
    height: 50px;
    text-decoration: none;
    font-size: 1em;
    margin-top: 1em;
    margin-bottom: 20px;
    border: 2px solid #0075C9;
    position: relative;
    overflow: hidden;
    transition: all 0.5s;
    display: flex;
    align-items: center;
}

.affiliated-section .meet-our-affiliates p {
    position: relative;
    z-index: 3;
    width: 100%;
    text-align: center;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin: 0 !important;
}

.affiliated-section .meet-our-affiliates:hover p {
    color: #FFF;
    transition: color 0.25s;
}

.affiliated-section .meet-our-affiliates div {
    position: absolute;
    top: 0;
    left: -20px;
    background-color: #0075C9;
    height: 100%;
    width: 0px;
    z-index: 1;
    transition: all 0.5s;
    transform: skew(.4rad);
}

.affiliated-section .meet-our-affiliates:hover div {
    width: 100%;
    transition: all 0.5s;
    transform: skew(0);
    left: 0px;
}

.affiliated-section .meet-our-affiliates:hover {
    transition: all 0.5s;
}

@media only screen and (max-width: 960px) {
    .affiliated-section img {
        transform: scale(0.9);
    }
}

@media only screen and (max-width: 700px) {

    .affiliated-section {
        height: 600px;
    }
    
    .affiliated-section ul {
        height: 350px;
        flex-direction: column-reverse;
    }
    
    .affiliated-section ul li {
        max-width: 100%;
    }
}
.selected .selection-timer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: rgb(228, 228, 228);
}

.selection-timer > div {
    width: 0%;
    transition: width 10s linear;
}

.selected.noInterval .selection-timer > div {
    transition: none;
}

.selected .selection-timer > div {
    background-color: #0075C9;
    width: 100%;
    height: 5px;
    position: absolute;
    top: 0;
    left: 0;
}

.list-component {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 90vh;
    background-color: #f5f3f0;
    color: #1A1F39;
}

.list-component h1 span {
    color: #0075C9;
}

.list-component .content {
    width: 90%;
    margin-left: 5%;
    height: 100%;
}

.list-component .content .divider {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    height: 100%;
}

.list-component .content .divider:nth-child(1){
    width: 35%;
}
.list-component.reversed .content .divider:nth-child(1) {
    width: 55%;
}
.list-component .content .divider:nth-child(2) {
    width: 65%;
}
.list-component.reversed .content .divider:nth-child(2) {
    width: 45%;
}

.list-component .content .divider > div {
    text-align: left;
    display: flex;
    flex-direction: column;
    margin: 25px;
}
.list-component .content .divider:nth-child(1) > div {
    margin-left: 0px;
}
.list-component .content .divider:nth-child(2) > div {
    margin-right: 0px;
}

.list-component .content .divider .services-illustration {
    background-color: #0d122c;
    height: 20%;
}

.list-component .content .divider .services-summary h5 {
    opacity: 0.5;
    letter-spacing: 2px;
    margin-bottom: 0.75em;
    margin-top: 0.75em;
}

.list-component .content .divider .services-summary p {
    opacity: 0.75;
}

.list-component .content .divider .collapsible-list {
    height: 100%;
}

.list-component .collapsible-list > ul {
    list-style: none;
    padding: 0;
    height: 100%;
}

.list-component .collapsible-list > ul > li {
    background-color: #FFF;
    height: 75px;
    max-height: 75px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
    transition: all 0.75s;
}

.list-component .collapsible-list ul li button {
    background-color: #FFF;
    color: #0075C9;
    outline: none;
    border: none;
    width: 100%;
    font-size: 1.2em;
    line-height: 0.6em;
    height: 75px;
    text-align: left;
    cursor: pointer;
    
}
.list-component .collapsible-list ul li button.headline-btn {
    display: flex;
}

.list-component .collapsible-list ul li h3 {
    display: inline-block;
    font-size: 1.5em;
    line-height: 1em;
    margin-top: 0.75em;
}
.list-component .collapsible-list ul li h3.index {
    width: 30px;
    left: -10px;
    position: relative;
}
.list-component .collapsible-list ul li h3.index {
    width: 30px;
}
.list-component .collapsible-list ul li h3.headline {
    flex-grow: 1;
    padding-left: 10px;
}
.list-component .collapsible-list ul li h3 span {
    padding-right: 15px;
}

.list-component .collapsible-list .list-item-info ul {
    list-style: none;
    padding: 0;
}
.list-component .collapsible-list .list-item-info ul li {
    opacity: 0.75;
}
.list-component .collapsible-list .list-item-info ul li div {
    display: inline-block;
    height: 2px;
    width: 10px;
    margin-bottom: 4px;
    margin-right: 4px;
    background-color: #005FA6;
}


.list-component .collapsible-list ul li.selected {
    transition: all 0.75s;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: inherit;
}

.list-component .collapsible-list ul li.wrap {
    height: 100px;
    max-height: 100px;
}


.list-component .collapsible-list ul li .list-item-info {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #FFF;
    color: #1A1F39;
    padding-left: 50px;
    padding-top: 10px;
}
.list-component .collapsible-list ul li .learn-more {
    margin-left: 50px;
    width: 200px;
    cursor: pointer;
    background-color: #FFF;
    color: #0075C9;
    font-weight: 900;
    height: 50px;
    text-decoration: none;
    font-size: 1em;
    margin-top: 1em;
    margin-bottom: 20px;
    border: 2px solid #0075C9;
    position: relative;
    overflow: hidden;
    transition: all 0.5s;
}

.list-component .collapsible-list ul li .learn-more p {
    position: relative;
    z-index: 3;
    width: 100%;
    text-align: center;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin: 0 !important;
}

.list-component .collapsible-list ul li .learn-more:hover p {
    color: #FFF;
    transition: color 0.25s;
}

.list-component .collapsible-list ul li .learn-more div {
    position: absolute;
    top: 0;
    left: -20px;
    background-color: #0075C9;
    height: 100%;
    width: 0px;
    z-index: 1;
    transition: all 0.5s;
    transform: skew(.4rad);
}

.list-component .collapsible-list ul li .learn-more:hover div {
    width: 100%;
    transition: all 0.5s;
    transform: skew(0);
    left: 0px;
}

.list-component .collapsible-list ul li .learn-more:hover {
    transition: all 0.5s;
}

@media only screen and (max-width: 700px) {
    .list-component {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        min-height: 100vh;
    }
    .list-component .content .divider > div {
        text-align: left;
        display: flex;
        flex-direction: column;
        margin: 25px 0px;
    }
    .list-component .content .divider {
        width: 100% !important;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .list-component .content .divider .services-illustration {
        display: none;
        height: 0;
    }
    .list-component .content .divider .collapsible-list {
        margin-top: -20px;
    }
}
.page-header {
    max-width: 450px;
    bottom: 100px;
    position: relative;
}
.page-header h1 {
    margin: 0;
    line-height: 0.9em;
    font-size: 2.5em;
    letter-spacing: -2px;
}
.page-header.blue > p { color: #1b2a39; }
.page-header.blue h1 { color: #1b2a39; }
.page-header.blue h1 span { color: #0075C9; }

@media only screen and (max-width: 700px) {
    .page-header {
        max-width: 100%;
        text-align: center;
    }
 }
.Cliff_cliff__2V_KK {
    background: rgb(240,252,255);
    background: linear-gradient(30deg,#0D122C 0%, #F0FCFF 20%,  #8CD1F1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f0fcff",endColorstr="#008ad0",GradientType=1);
    background: #0D122C;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    padding: 50px 0;
}
.Cliff_cliffParallax__3wtr2 {
    background: rgb(240,252,255);
    background: linear-gradient(30deg,#0D122C 0%, #F0FCFF 20%,  #8CD1F1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f0fcff",endColorstr="#008ad0",GradientType=1);
    
    padding: -10px;
    /* border: 5px solid #0D122C;
    border-radius: 10px; */
    
}
.Cliff_cliffParallax__3wtr2,
.Cliff_cliffText__3aMNw {
    width: 40%;
    position: relative;
    height: 400px;
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.Cliff_cliffParallax__3wtr2 img {
    width: 100%;
    max-width: 500px;
}
.Cliff_cliff__2V_KK .Cliff_bg__PtPME {
    background: #0D122C;
    height: 150px;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 1;
}
.Cliff_cliff__2V_KK .Cliff_foreground__1GCTQ,
.Cliff_cliff__2V_KK .Cliff_backdrop__1wVtQ {
    display: flex;
    justify-content: center;
}
.Cliff_cliff__2V_KK .Cliff_left__2c3Rl,
.Cliff_cliff__2V_KK .Cliff_right__1q2hH {
    position: absolute;
    height: 100%;
    width: 30px;
    z-index: 3;
    top: 0;
    width: 5px;
    /* display: none; */
}
.Cliff_cliff__2V_KK .Cliff_left__2c3Rl {
    left:0;
    background: rgba(13, 18, 44, 0);
    background: linear-gradient(90deg,rgba(13, 18, 44, 1) 0%, rgba(13, 18, 44, 1) 40%, rgba(26, 31, 57, 0) 100%);
}
.Cliff_cliff__2V_KK .Cliff_right__1q2hH {
    right: 0;
    background: rgba(13, 18, 44, 0);
    background: linear-gradient(-90deg,rgba(13, 18, 44, 1) 0%, rgba(13, 18, 44, 1) 40%, rgba(26, 31, 57, 0) 100%);
}
/* .cliff .foreground img,
.cliff .backdrop img {
    max-width: 1000px;
} */
.Cliff_cliff__2V_KK .Cliff_clouds__2bHqN img {
    max-height: 300px;
}
.Cliff_foregroundCover__BX0K0 {
    background: #0D122C;
    height: 50px;
    z-index: 3;
    width: 100%;
}
@media only screen and (max-width: 850px) {
    .Cliff_cliff__2V_KK {
        flex-direction: column;
    }
    .Cliff_cliffParallax__3wtr2,
    .Cliff_cliffText__3aMNw{
        width: 100%;
    }
}
.about section {
    width: 100%;
    color: #1b2a39;
    background-color: #FFF;
    height: 100vh;
    max-height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about .header {
    height: 500px;
    background-color: #FFF;
}

.about .header .team-photo {
    color: rgba(0, 0, 0, 0.15);
    margin: 20px;
    width: 100%;
    height: 90%;
    border: 5px dashed rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
}
.about .explainer {
    height: 200px;
    background-color: #F4F5F7;
}

.about .explainer p {
    margin: 10%;
}
.funds {
    background-color: #F5F3F0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.funds .intro {
    display: flex;
    justify-content: space-between;
    color: #1b2a39;
    width: 100%;
    border-bottom: 1px solid #d8dee6;
    max-width: 1400px;
}

.funds .intro > div {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px 30px;
}

.funds .intro > div h5  {
    letter-spacing: 2px;
    margin-bottom: 0.75em;
    margin-top: 0.75em;
    color: #005FA6;
}
.funds .intro > div h3, .funds .intro > div h5  {
    margin: 0;
}

.funds .intro > div h3  {
    color: #1b2a39;
    font-size: 1.5em;
    text-align: left;
}

.funds .funds-scroll { 
    width: 100%;
    position: relative;
}

.funds .intro p {
    font-size: 0.75em;
    text-align: right;
    margin: 0;
    opacity: 0.75;
}

.funds .bg {
    z-index: 1;
    position: absolute;
    height: 50vh;
    width: 60vw;
    left: 0;
    right: 0;
    margin: auto;
    top: 50px;
    background-color: #FFC20E;
    background-color: #0075C9;
}
.funds-list {
    z-index: 2;
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    overflow: hidden;
    padding-bottom: 200px;
    height: 450px;
}

.info-table li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1em;
    list-style: none;
    border: none;
    border-bottom: 1px solid #d8dee6;
}
.investment-approach {
    margin: 0;
    padding: 0;
    text-align: left;
    list-style: auto;
}

.investment-approach ul li {
    margin: 0;
    padding: 0;
    width: 100%;
    border: none;
}

.funds-scroll .bottom-nav {
    position: absolute;
    z-index: 2;
    bottom: 50px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
}

.funds-scroll .bottom-nav .current-position {
    display: flex;
    align-items: center;
}

.funds-scroll .bottom-nav .current-position > div {
    width: 50px;
    height: 3px;
    margin: 10px;
    background-color: #005FA6;
}

.funds-scroll .bottom-nav .next {
    margin-right: 5%;
    padding-left: 30px;
}

.funds-scroll .bottom-nav .next p {
    margin-right: 20px;
    font-weight: 600;
}

.funds-scroll .bottom-nav .prev {
    margin-left: 5%;
}

.funds-scroll .bottom-nav .prev p {
    margin-left: 20px;
    font-weight: 600;
}

.funds-scroll .bottom-nav .next, .funds-scroll .bottom-nav .prev {
    display: flex;
}

.funds-scroll .bottom-nav button {
    background-color: #FFC20E;
    background-color: #0075C9;
    height: 50px;
    width: 50px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.funds-scroll .bottom-nav button > div {
    background-color: #FFF;
    height: 2px;
    width: 7px;
    position: relative;
}

.funds-scroll .bottom-nav .prev button > div:nth-child(1) {
    transform: rotate(-45deg);
    left: 2px;
    bottom: 2px;
}

.funds-scroll .bottom-nav .prev button > div:nth-child(2) {
    transform: rotate(45deg);
    right: 5px;
    top: 2px;
}

.funds-scroll .bottom-nav .next button > div:nth-child(1) {
    transform: rotate(45deg);
    left: 5px;
    bottom: 2px;
}

.funds-scroll .bottom-nav .next button > div:nth-child(2) {
    transform: rotate(-45deg);
    right: 2px;
    top: 2px;
}

.funds .contact-us {
    display: flex;
    justify-content: space-between;
    background-color: #0d122c;
    height: 700px;  
    width: 100%;  
}

@media screen and (max-width: 700px) {
    .funds-scroll .bottom-nav p {
        display: none;
    }
    .funds-scroll .bottom-nav .current-position > div {
        width: 20px;
        margin: 5px;
    }
    .funds-scroll .bottom-nav .next {
        padding-left: 0px;
    }
}


@media screen and (min-width: 1400px) {
    .funds .intro > div {
        padding: 20px 10px;
    }
}
.fund-summary {
    height: 350px;
    width: 300px;
    max-width: 90vw;
    background-color: #0d122c;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    list-style: none;
    text-align: left;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    transition: all 0.5s;
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    margin: auto;
}
.fund-summary .top, .fund-summary .bottom {
    color: #FFF;
    height: auto;
}

.fund-summary .top {
    display: flex;
    margin: 10px;
    justify-content: space-between;
}

.fund-summary .top h3, .fund-summary .top p {
    margin: 0;
    font-weight: 600;
    
}
.fund-summary .top h3 span, .fund-summary .top p span {
    color: #FFC20E;
    color: #FFF;
}
.fund-summary .top .performance {
    white-space: nowrap;
    text-align: right;
}
.fund-summary .top p {
    font-size: 12px;
}

.fund-summary .middle {
    background-color: #FFF;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.fund-summary .middle img {
    height: 120px;
}

.fund-summary.focus {
    top: 70px;
    width: 70vw;
}
.fund-summary .bottom {
    margin: 15px;
    font-size: 12px;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.fund-summary .bottom p {
    margin: 0px;
}
.fund-summary .learn-more {
    width: 130px;
    cursor: pointer;
    background-color: none;
    color: #FFC20E;
    color: #0075C9;
    font-weight: 900;
    height: 35px;
    text-decoration: none;
    font-size: 12px;
    margin-top: 15px;
    margin-bottom: 10px;
    border: 2px solid #FFC20E;
    border: 2px solid #0075C9;
    position: relative;
    overflow: hidden;
    transition: all 0.5s;
    display: flex;
    align-items: center;
}

.fund-summary .learn-more p {
    position: relative;
    z-index: 3;
    width: 100%;
    text-align: center;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin: 0 !important;
}

.fund-summary .learn-more:hover p {
    color: #FFF;
    transition: color 0.25s;
}

.fund-summary .learn-more div {
    position: absolute;
    top: 0;
    left: -20px;
    background-color: #FFC20E;
    background-color: #0075C9;
    height: 100%;
    width: 0px;
    z-index: 1;
    transition: all 0.5s;
    transform: skew(.4rad);
}

.fund-summary .learn-more:hover div {
    width: 100%;
    transition: all 0.5s;
    transform: skew(0);
    left: 0px;
}

.fund-summary .learn-more:hover {
    transition: all 0.5s;
}

@media only screen and (max-width: 500px) {
    .fund-summary {
        max-width: 70vw;
    }
    .fund-summary .top h3 {
        font-size: 0.9em;
    }
    .fund-summary .bottom p {
        font-size: 0.8em;
    }
 }
 
.PerformanceChart_graphContainer__1rM0J {
    width: 100%;
    height: 100%;
    background: white;
}


.line-graph-wrapper {
    display: block;
    position: relative;
    color: #4b4f56;
    background-color: #FFF;
    margin-bottom: 8px;
    counter-reset: section 2;
    font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    font-size: 10px;
    width: 100%;
    /* max-width: 1199px; */
    border-radius: 4px;
    
}

.line-graph-wrapper .graph-line {
    stroke: #0075C9;
}

.line-graph-wrapper .graph-line-fill {
    stroke: #0075C9;
    stroke-width: 1px;
    fill: #E9F2F9;
    /* fill-opacity: 0.1; */
}

.line-graph-wrapper .option-toggles {
    display: flex;
    justify-content: flex-end;
}

.line-graph-wrapper .fund-type {
    width: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.line-graph-wrapper .time-periods {
    width: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.line-graph-wrapper .fund-type div {
    -webkit-box-flex: 1;
    position: relative;
    width: 100px;
}

.line-graph-wrapper .time-periods div {
    -webkit-box-flex: 1;
    position: relative;
    width: 50px;
}

.line-graph-wrapper .time-periods div button, .line-graph-wrapper .fund-type div button {
    display: block;
    position: relative;
    width: 100%;
    font: inherit;
    color: inherit;
    padding: 6px 3px;
    -webkit-font-smoothing: inherit;
    letter-spacing: inherit;
    cursor: pointer;
    overflow: visible;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    outline: none;
    font-size: 1.2em;
    background: none;
    border-bottom: 1px solid #F5F3F0;
}

.line-graph-wrapper .time-periods div button.selected, .line-graph-wrapper .fund-type div button.selected {
    color: #0075C9;
    font-weight: bold;
}

.line-graph-wrapper .time-periods div button.selected::after, .line-graph-wrapper .fund-type div button.selected:after {
    position: absolute;
    content: " ";
    display: block;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 3px;
    background: #0075C9;
}

.line-graph-wrapper .top-axis, .line-graph-wrapper .graph-area {
    cursor: grab;
}
.line-graph-wrapper .top-axis:active, .line-graph-wrapper .graph-area:active {
    cursor: grabbing;
}
.line-graph-wrapper .body {
    position: relative;
    -webkit-user-select: none;
            user-select: none;
    min-height: 350px;
    padding-top: 16px;
}


.line-graph-wrapper .header-wrapper .current-x {
    position: absolute;
    top: 20px;
}

.line-graph-wrapper .header-wrapper .close {
    left: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    position: absolute;
    right: auto;
    padding: 4px 8px;
    border-radius: 3px 3px 0px 0px;
    background: rgba(245, 243, 240, 0.5);
}
.line-graph-wrapper .header-wrapper .close.switched {
    left: auto;
    right: 0px;
}

.line-graph-wrapper .header-wrapper .close p {
    font-weight: normal;
    text-align: right;
    white-space: nowrap;
    line-height: 1.4;
    font-size: 1.2em;
    margin: 0px;
    color: #0075C9;
}
.line-graph-wrapper .header-wrapper .close p > strong {
    font-weight: 900;
    font-size: 1.2em;
    
    color: #1b2a39;
}

.line-graph-wrapper .annotations {
    text-align: right;
}
.line-graph-wrapper .annotations svg {
    margin-right: 4px;
}

.line-graph-wrapper .annotations button {
    cursor: pointer;
    margin-left: 2px;
    margin-bottom: 2px;
    font-size: 1.2em;
    color: #4b4f56;
    text-transform: capitalize;
    padding: 4px 10px 4px 8px;
    background: #F5F6F7;
    border-width: 1px;
    border-style: solid;
    border-color: #F5F6F7;
    border-image: initial;
    border-radius: 100px;
    outline: none;
}

.line-graph-wrapper .annotations button.hidden {
    color: #AAA;
    background: #FFF;
}

.line-graph-wrapper .annotations button.hidden svg * {
    fill: #FFF;
}

.create-annotation {
    cursor: pointer;
    color: #4b4f56;
    text-transform: capitalize;
    padding: 4px;
    background: #FFF;
    border-width: 1px;
    border-style: solid;
    border-color: #F5F6F7;
    border-image: initial;
    border-radius: 100px;
    outline: none;
}
.create-annotation input, .create-annotation button {
    font-size: 1.2em;
    color: #4b4f56;
    padding: 4px 10px 4px 8px;
    background: #FFF;
    border-width: 1px;
    border-style: solid;
    border-color: #F5F6F7;
    border-radius: 100px;
    outline: none;
}

.annotation-popup-bg {
    z-index: 10;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100%;
    background-color: rgba(0,0,0,0.25);
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.annotation-popup {
    background-color: #FFF;
    position: relative;
    display: block;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 0px;
    max-width: 700px;
}

.annotation-popup-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: black;
    overflow-y: auto;
    overflow-x: hidden;
    background: white;
    padding: 20px 24px;
}
.annotation-popup-body .linked-document {
    display: flex;
    flex-direction: column;
}
.annotation-popup-body .linked-document embed {
    height: 400px;
    margin: 20px 0;
}

.annotation-popup-body .linked-document a {
    position: relative;
    line-height: 1;
    -webkit-user-select: none;
            user-select: none;
    vertical-align: top;
    font-size: 1rem;
    height: 40px;
    color: #0075C9;

    flex: 1 1;
    background: #FFF;
    border: 1px solid #0075C9;
    text-decoration: none;
    padding: 10px 16px;
    outline: none;
}


.annotation-popup-body h3 {
    font-size: 1.1rem;
}
.annotation-popup-body p {
    font-size: 0.8rem;
    line-height: 1.5;
}

.annotation-popup header {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    border-bottom: 1px solid rgb(232, 232, 232);
    font-size: 1rem;
    font-weight: 300;
    
}
.annotation-popup header h3 {
    margin: 5px 0px;
}

.annotation-popup header button {
    background: transparent;
    outline: none;
    border: none;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.annotation-popup header button div {
    background: #000;
    height: 15px;
    width: 2px;
}
.annotation-popup header button div:nth-child(1) {
    transform: rotate(45deg) translateX(1.5px);
}
.annotation-popup header button div:nth-child(2) {
    transform: rotate(-45deg) translateX(-1.5px);
}

.annotation-popup footer {
    display: flex;
    justify-content: center;
    padding: 16px 24px;
    border-top: 1px solid rgb(232, 232, 232);
}

.annotation-popup footer button {
    position: relative;
    line-height: 1;
    -webkit-user-select: none;
            user-select: none;
    vertical-align: top;
    font-size: 1rem;
    height: 40px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    background: #0075C9;
    padding: 0px 16px;
    border: none;
    outline: none;
}

@media (min-width: 768px) {
    .annotation-popup-bg {
        padding: 32px;
    }
}

@media (max-width: 700px) {
    .line-graph-wrapper .fund-type div:nth-child(1) {
        width: 60px;
    }
}

.fund > div > section {
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

.fund .performance {
    background-color: #FFF;
    min-height: 200px;
}
.fund .performance > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fund .performance .description {
    text-align: left;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
}
.fund .performance .description img {
    height: 150px;
}

.fund .performance .description a {
    color: #0075C9;
    font-weight: bold;
    text-decoration: none;
    margin: 0;
    font-size: 0.8em;
    position: relative;
    display: flex;
    bottom: 10px;
}

.fund .performance .description a > div {
    position: relative;
    background-color: #0075C9;
    height: 7px;
    width: 2px;
    margin: 2px;
    transform: rotate(45deg);
    top: 2px;
}
.fund .performance .description a > div:nth-child(2) {
    transform: rotate(-45deg);
    right: 6px;
    top: 6px;
}

.fund .performance .description h1 {
    color: #1b2a39;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 2.5em;
    line-height: .9em;
    max-width: 350px;
}
.fund .performance .description p {
    color: #1b2a39;
    margin: 0;
    max-width: 350px;
}

.fund .summary {
    background-color: #F5F3F0;
}
.fund .disclaimer {
    
}

.fund .summary > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.key-info table {
    border-spacing: 0;
    border-collapse: collapse;
    margin-bottom: 20px;

}
.key-info table td {
    /* border: 1px solid rgba(177,164,166,.4);
    border-bottom: 1px solid #0075C9; */
    text-align: left;
    padding: 10px;
}
.key-info table td span {
    color: #0075C9;
    font-size: 0.75em;
    font-weight: 600;
}

.fund-supplements {
    padding-bottom: 20px;
    text-align: left;
}
.fund-supplements .supplement-doc {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    color: #0075C9;
    font-weight: 600;
    padding: 1em;
    height: 100px;
    width: 100px;
    vertical-align: top;
    margin: 10px;

    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    border: 1px solid #e9ebee;
    border-bottom: 3px solid #e9ebee;
}

.fund-supplements .supplement-doc:hover {
    border-bottom: 3px solid #0075C9;
}
.fund-supplements .supplement-doc a {
    text-decoration: none;
    color: #0075C9;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.fund .graph-loading {
        display: block;
        position: relative;
        color: #4b4f56;
        background-color: #FFF;
        margin-bottom: 8px;
        counter-reset: section 2;
        font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
        font-size: 10px;
        width: 100%;
        border-radius: 4px;
}

.loading.fund {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

}

.fact-sheets {
    overflow: scroll;
}

.fact-sheets-container {
    text-align: left;
    padding-bottom: 20px;
}
.fact-sheets a,
a.application-link {
    display: inline-block;
    text-decoration: none;
    color: #0075C9;
    padding: 1em;
    margin: 10px;
    width: 25%;
    font-weight: 600;
    text-align: center;
    border: 1px solid #e9ebee;
    border-bottom: 3px solid #e9ebee;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
}
a.application-link {
    background: #0075C9;
    color: #FFF;
    position: relative;
    float: left;
    white-space: nowrap;
    width: auto;
    margin-left: 0;
    margin-bottom: 30px;
}
.fact-sheets a:hover {
    border-bottom: 3px solid #0075C9;
}


@media screen and (min-width: 1200px) {
    .fund > div > section:nth-child(1),.fund > div > section:nth-child(2) {
        max-width: 50%;
    }
    
}

@media screen and (max-width: 600px) {
    .fund .performance .description {
        flex-direction: column-reverse;
        text-align: center;
        align-items: center;
    }
    .fund .performance .description h1 {
        font-size: 1.25em;
        width: 100%;
        max-width: 100%;
    }
    .fund .performance .description p {
        width: 100%;
        max-width: 100%;
    }
    .fund .performance .description img {
        width: auto;
        height: 130px;
        position: relative;
        top: 20px;
    }
    .fund .performance .description > div {
        
        width: 100%;

    }
    .fund .performance .description > div a {
        /* justify-content: center; */
        padding-top: 20px;
        position: relative;
        bottom: 160px;
    }
}
.missing-page {
    background-color: #F5F3F0;
}

.missing-message {
    padding: 100px 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.missing-message .text {
    display: flex;
    text-align: right;
    align-items: flex-end;
    flex-direction: column;
    color: #1b2a39;
    max-width: 50%;
}
.missing-message img {
    height: 300px;
    width: 360px;
}

.missing-message h1 {
    letter-spacing: -2px;
    font-size: 2.5em;
    line-height: 0.9em;
}
.missing-message p {
    margin-top: 0;
}

@media only screen and (max-width: 800px) {
    .missing-message {
        flex-direction: column;
    }
    .missing-message .text {
        max-width: 100%;
        align-items: center;
        text-align: center;
    }
    .missing-message img {
        height: 300px;
        
    }
 }
.affiliated .header {
    height: 500px;
    position: relative;
}

.affiliated .header img {
    position: absolute;
    top: 20%;
    right: 5%;
    height: 300px;
}

.affiliated section {
    width: 100%;
    color: #1b2a39;
    background-color: #FFF;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    display: block;
}

.affiliated section .content {
    height: 100%;
    position: relative;
    padding-left: 5%;
    padding-right: 5%;
}


.affiliated h1 {
    margin: 0;
    line-height: 0.9em;
    font-size: 2.5em;
    letter-spacing: -2px;
}

.affiliated .header .content .text {
    position: absolute;
    width: 500px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    z-index: 3;
    left: 10%;
    text-align: left;
    top: 0;
    bottom: 20%;
    margin: auto;
    transition: all 0.5s;
}

.affiliated .header .text h1 span {
    color: #0075C9;
}
.affiliated .header .text h5 {
    opacity: 0.5;
    letter-spacing: 2px;
    margin-bottom: 0.75em;
    margin-top: 0.75em;
}
.affiliated .header .text p {
    width: 410px;
}
.affiliated .affiliate.right {
    text-align: right;
}

.affiliated .affiliate.left {
    text-align: left;
}

.affiliated .affiliate .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.affiliated .affiliate .content .divider {
    width: 40%;
}

.affiliated .product-image {
    height: auto;
    width: 100%;
}
.affiliated .left .product-image {
    float: right;
}

@media only screen and (max-width: 900px) {
    .affiliated .header .content .text {
        position: absolute;
        top: 30%;
        bottom: 0;
        left: 0;
        text-align: center;
        width: 90%;
        margin: auto 20px;
    }
    .affiliated .affiliate.left {
        text-align: center;
    }
    .affiliated .affiliate.right {
        text-align: center;
    }
    .affiliated .affiliate .content {
        flex-direction: column;
        justify-content: center;
    }
    .affiliated .affiliate:nth-child(odd) .content {
        flex-direction: column-reverse;
    }
    .affiliated:first-child .affiliate .content {
        flex-direction: column;
    }
    .affiliated .affiliate.stake .content {
        flex-direction: column-reverse;
    }

    .affiliated .affiliate .content .divider {
        width: 100%;
        display: inline-block;
        margin: 20px;
        text-align: center;
    }
    .affiliated .affiliate .content .divider iframe {
        float: none !important;
        max-width: 100%;
    }
    .affiliated .product-image {
        height: 200px;
        width: auto !important;
        float: none !important;
    }
}



.affiliated section:nth-child(odd) .content .divider iframe {
    float: left;
}
.affiliated section:nth-child(even) .content .divider iframe {
    float: right;
}

.affiliated section:nth-child(even) {
    background-color: #F4F5F7;
}

.affiliated .cta {
    display: inline-block;
    cursor: pointer;
    background-color: transparent;
    color: #0075C9;
    font-weight: 900;
    text-decoration: none;
    font-size: 1em;
    padding: 0.8em;
    padding-left: 3em;
    padding-right: 3em;
    margin-top: 1em;
    border: 2px solid #0075C9;
    position: relative;
    overflow: hidden;
    transition: all 0.5s;
    margin: 10px;
}
.affiliated .cta.leftmost {
    margin-left: 0;
}
.affiliated .cta.rightmost {
    margin-right: 0;
}
.affiliated .cta.white {
    color: #FFF;
    border: 2px solid #FFF;
}

.affiliated .cta.unpad {
    padding-left: 0.8em;
    padding-right: 0.8em;
}

.affiliated .cta p {
    position: relative;
    z-index: 3;
    
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin: 0 !important;
}

.affiliated .cta:hover p {
    color: #FFF;
    transition: color 0.25s;
}

.affiliated .cta.white:hover p {
    color: #0d122c;
}

.affiliated .cta div {
    position: absolute;
    top: 0;
    left: -20px;
    background-color: #0075C9;
    height: 100%;
    width: 0px;
    z-index: 1;
    transition: all 0.5s;
    transform: skew(.4rad);
}

.affiliated .cta.white div {
    background-color: #FFF;
}

.affiliated .cta:hover div {
    width: 100%;
    transition: all 0.5s;
    transform: skew(0);
    left: 0px;
}

.affiliated .cta:hover {
    transition: all 0.5s;
}

.app-store-link {
    display: inline-block;
    position: relative;
    top: 15px;
}

.app-store-link {
    margin: 0 4px;
}


.affiliate-logo {
    height: 50px;
    width: auto;
}

/* MOBILE */

@media only screen and (max-width: 920px) {

    .affiliated .header {
        height: 700px;
        position: relative;
    }  
    .affiliated .header .page-header {
        top: 150px;
        max-width: 100%;
        text-align: center;
    }      
    .affiliated .header img {
        position: absolute;
        top: 100px;
        right: 0;
        left: 0;
        margin: auto;
        height: 200px;
    }

    .affiliated .header h1 {
        font-size: 2em;
    }
    .affiliated .space-mobile {
        padding: 40px 0;
    }
}
.contact {
    width: 100%;
    background: rgb(0,117,201); /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(0,117,201,1) 0%,rgba(0,95,166,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0075c9', endColorstr='#005fa6',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.privacy-policy {
    margin: 3em 0em;
    text-align: left;
    font-size: 12px;
    background-color: #0d122c;
    color: #FFF;
}

.privacy-policy h3 {
    font-size: 24px;
}
.privacy-policy h5 {
    font-size: 14px;
}
.melbourne.portfolio-services {
    background-color: #F5F3F0;
    padding: 5em 5%;
    padding-top: 75px;
    position: relative;
}
.sydney.portfolio-services {
    background-color: #FFF;
    padding: 5em 5%;
    position: relative;
}

.sydney.portfolio-services > p, .melbourne.portfolio-services > p {
    color: #888995;
    letter-spacing: 2px;
    margin-bottom: 0.75em;
    margin-top: 0.75em;
    font-weight: 900;
}
.sydney.portfolio-services > h1, .melbourne.portfolio-services > h1 {
    margin-bottom: 1em;
}

.melbourne.portfolio-services > div, .sydney.portfolio-services > div {
    height: 300px;
    width: auto;
    position: relative;
    display: inline-block;
    z-index: 1;
}
.sydney.portfolio-services .spacer {
    height: 50px;
    width: 100%;
}

.melbourne.portfolio-services > div > h3, .sydney.portfolio-services > div > h3 {
    position: absolute;
    font-size: 0.95em;
    bottom: 15px;
    left: 20px;
    color: #0075C9;
}
.melbourne.portfolio-services > div > a > svg, .sydney.portfolio-services > div > a > svg  {
    position: absolute;
    bottom: 5px;
    right: -4px;
    transform: scale(0.3);
}

.melbourne.portfolio-services > div > img, .sydney.portfolio-services > div > img {
    height: 300px;
    width: auto;
}

.melbourne.portfolio-services .melbourne-skyline, .sydney.portfolio-services .sydney-skyline  {
    position: absolute;
    bottom: -1px;
    left: 0;
    z-index: 0;
    width: 100%;
    height: auto;
}
.portfolio-services .header {
    width: 100%;
    color: #1b2a39;
    background-color: #FFF;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: block;
}

.portfolio-services .header .content {
    height: 100%;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.portfolio-services .header .content img {
    height: 300px;
}
.portfolio-services h1 {
    margin: 0;
    line-height: 0.9em;
    font-size: 2.5em;
    letter-spacing: -2px;
}

.portfolio-services .header .content .text {
    width: 500px;
    max-width: 90%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    z-index: 3;
    text-align: right;
    transition: all 0.5s;
}

.portfolio-services .header .text h1 span {
    color: #0075C9;
}
.portfolio-services .header .text h5 {
    opacity: 0.5;
    letter-spacing: 2px;
    margin-bottom: 0.75em;
    margin-top: 0.75em;
}
.portfolio-services .header .text p {
    width: 410px;
    max-width: 100%;
    text-align: right;
    margin-right: 0;
    float: right;
}

.portfolio-services .detail {
    background-color: #0d122c;
    color: #FFF;
    padding: 50px;
    text-align: right;
}

.portfolio-services .detail .content {
    height: 100%;
    position: relative;
    width: 90%;
    margin-right: 5%;
}

.portfolio-services .detail .content .text .left, .portfolio-services .detail .content .text .right {
    margin-top: 150px;
}
.portfolio-services .detail .content .text .left {
    text-align: left;
    max-width: 300px;
}
.portfolio-services .detail .content .text .right {
    
    text-align: right;
    max-width: 300px;
}

@media only screen and (max-width: 850px) {
    .portfolio-services .detail {
        text-align: center;
    }
    .portfolio-services .header {
        height: 700px;
    }
    .portfolio-services .header .content {
        height: 100%;
        flex-direction: column;
        justify-content: space-evenly;
        
    }
    .portfolio-services .header .content .text {
        text-align: center;
    }
    .portfolio-services .header .text p {
        float: none;
        text-align: center;
        width: 100%;
    }
    .portfolio-services .header .content img {
        height: 200px;
    }
    .portfolio-services h1 {
        font-size: 1.75em;
    }
}
.centered-list {
    color: #1A1F39;
    text-align: center;
}
.centered-list .title {
    padding: 50px 0;
}
.centered-list .title h1 {
    margin: 0;
    line-height: 0.9em;
    font-size: 2.5em;
    letter-spacing: -2px;
}
.centered-list .title h1 span {
    color: #0075C9;
}

.centered-list ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 0;
    padding-bottom: 100px;
}

.centered-list ul li {
    width: 250px;
    padding: 20px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
    margin: 20px 0px;
    text-align: center;
    position: relative;
}
.centered-list ul li h3 {
    text-align: center;
}
.centered-list ul li .title-underline {
    height: 4px;
    width: 80px;
    background: #0075C9;
    
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}
.centered-list ul li p {
    padding-top: 20px;
}


@media only screen and (max-width: 900px) {
    .centered-list ul {
        flex-direction: column;
        align-items: center;
    }
}
.content.fs {
    padding-top: 50px;
}
.finance-services {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    height: 500px;

}
.finance-services > div {
    height: 100px;
    min-width: 350px;
    width: 80%;
    padding: 10px 15px;
    border-radius: 5px;
    background: #FFF;
    color: #1A1F39;
    border-left: 5px solid #0075C9;
    font-weight: 700;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    text-align: left;
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin: 10px 0;
}
@media only screen and (min-width: 1200px) {
    .finance-services {
        padding-right: 80px;
    }
}
@media only screen and (min-width: 1000px) {
    .finance-services {
        padding-right: 50px;
    }
}
@media only screen and (max-width: 850px) {
    .finance-services {
        padding-right: 0;
        align-items: center;
    }
    .finance-services > div {
        width: 90%;
    }
}
.wealth-advisory .header {
    width: 100%;
    color: #1b2a39;
    background-color: #FFF;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: block;
}

.wealth-advisory .header .content {
    height: 100%;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.wealth-advisory .header .content img {
    height: 300px;
}
.wealth-advisory h1 {
    margin: 0;
    line-height: 0.9em;
    font-size: 2.5em;
    letter-spacing: -2px;
}

.wealth-advisory .header .content .text {
    width: 500px;
    max-width: 90%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    z-index: 3;
    text-align: right;
    transition: all 0.5s;
}

.wealth-advisory .header .text h1 span {
    color: #0075C9;
}
.wealth-advisory .header .text h4 {
    color: #0075C9;
}
.wealth-advisory .header .text h5 {
    opacity: 0.5;
    letter-spacing: 2px;
    margin-bottom: 0.75em;
    margin-top: 0.75em;
}
.wealth-advisory .header .text p {
    width: 410px;
    max-width: 100%;
    text-align: right;
    margin-right: 0;
    float: right;
}

.portfolio-services .detail {
    background-color: #0d122c;
    color: #FFF;
    padding: 50px;
    text-align: right;
}
.wealth-advisory .detail .content {
    height: 100%;
    position: relative;
    width: 90%;
    margin-right: 5%;
}

.wealth-advisory .detail .content .text .left, .wealth-advisory .detail .content .text .right {
    margin-top: 150px;
}
.wealth-advisory .detail .content .text .left {
    text-align: left;
    max-width: 300px;
}
.wealth-advisory .detail .content .text .right {
    
    text-align: right;
    max-width: 300px;
}

@media only screen and (max-width: 850px) {
    .wealth-advisory .detail {
        text-align: center;
    }
    .wealth-advisory .header {
        height: 700px;
    }
    .wealth-advisory .header .content {
        height: 100%;
        flex-direction: column;
        justify-content: space-evenly;
        
    }
    .wealth-advisory .header .content .text {
        text-align: center;
    }
    .wealth-advisory .header .text p {
        float: none;
        text-align: center;
        width: 100%;
    }
    .wealth-advisory h1 {
        font-size: 1.75em;
    }
    .wealth-advisory .header .content img {
        height: 200px;
    }
}
.fsg {
    height: 300px;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #0d122c;
}
.fsg h3 {
    margin-top: 3em;
    font-size: 24px;
    color: #FFF;
}

.fsg div {
    width: 500px;
    text-align: left;
    padding: 0.5em 0;
}
.fsg a {
    padding: 0.5em 0;
    font-weight: 600;
    text-decoration: none;
    color: #0075C9;
    font-weight: bold;
}

.fsg a svg { 
    transform: scale(0.75);
    position: relative;
    left: 10px;
    top: 5px;
}
.terms-conditions {
    margin: 3em 0em;
    text-align: left;
    font-size: 12px;
    color: #FFF;
}

.terms-conditions a {
    color: #0075C9;
    font-weight: bold;
}

.terms-conditions h3 {
    font-size: 24px;
}
.terms-conditions h5 {
    font-size: 14px;
}
.nav_wrap {
   position: absolute;
   height: 100%;
   width: 100%;
   overflow: hidden;
}

nav {
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color:#0075C9;
    height: 50px;
}

.nav_main {
   padding: 0;
   margin: 0;
   width: 100%;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   transition: all 0.5s;
   max-width: 1400px;
}

.nav_main li {
    list-style: none;
    height: 50px;
}

.nav_main li:nth-child(1) a {
   padding: 10px 10px;
}  

.nav_main li a {
   z-index: 2;
   font-size: 0.85em;
   padding: 15px 10px;
   font-weight: 600;
   color: #F5F3F0;
   text-decoration: none;
   display: block;
}

.nav_main li a:hover {
    color: #FFF;
    background-color: #015FA6;
    padding-bottom: 16px;
}

.nav_main li a:active, .nav_main li a:focus {
   
   outline: none;
}

.nav_main li:nth-child(1) a:active, .nav_main li:nth-child(1) a:focus {
   border-bottom: none;
   outline: none;
}

.nav_main li:nth-child(1) svg {
   fill: #F5F3F0;
}
.nav_main li:nth-child(1):hover svg {
   fill: #FFF;
}

nav #logo {
   height: 27px;
   width: auto;
}

.nav_main li:nth-child(1) a:hover {
   background-color: transparent;
}

.nav_main li:nth-child(2) {
   flex-grow: 2;
   max-width: 300px;
   margin-left: 15px;
   position: relative;
   height: 50px;
   overflow: visible;
}
.nav_main li:nth-child(3) {
   flex-grow: 1;
}

.nav_main #globe {
   height: 50px;
   width: 50px;
   background: none;
   border: none;
   stroke: #F5F3F0;
   fill: #F5F3F0;
}

.nav_main .services-btn {
   height: 50px;
   color: #F5F3F0;
   background: none;
   border: none;
   font-size: 0.85em;
   padding: 15px 10px;
   padding-right: 25px;
   font-weight: 600;
   position: relative;
}

.services-btn > div {
   height: 50px;
   width: 20px;
   padding: 0;
   margin: 0;
   position: absolute;
   top: 0;
   right: 0;
   display: flex;
   flex-direction: center;
   align-items: center;
}

.services-btn > div > div {
   background-color: #F5F3F0;
   height: 1px;
   width: 7px;
   position: relative;
   top: 1px;
   transition: 0.5s;
}

.services-btn > div > div:nth-child(1) {
   transform: rotate(45deg);
   left: 1px;
}

.services-btn > div > div:nth-child(2) {
   transform: rotate(-45deg);
   right: 2px;
}
/* .services-btn.active > div > div {
   width: 10px;
} */
.services-btn.active > div > div:nth-child(2) {
   
   transform: rotate(45deg);
}
.services-btn.active > div > div:nth-child(1) {
   transform: rotate(-45deg);
}

.nav_main #globe:hover, .nav_main .services-btn:hover {
   cursor: pointer;
   stroke: #FFF;
   fill: #FFF;
   background-color: #015FA6;
   color: #FFF;
}

.nav_main #globe:active, .nav_main #globe:focus {
   background-color: #0075C9;
   outline: none;
}

.nav_main .services-btn:active, .nav_main .services-btn:focus {
   outline: none;
   
}


nav #globe svg {
   height: 20px;
   width: 20px;
}

.contact-link {
   padding: 7px !important;
}

.contact-link > div {
  cursor: pointer;
  background-color: #0075C9;
  border: 1px solid #F5F3F0;
  color: #F5F3F0;
  padding: 0.5em 1em;
  margin: 0;
  font-weight: 600;
  transition: background-color 0.25s;
}

.contact-link:hover {
   background-color: #0075C9 !important;
}
.contact-link:hover > div {
   background-color: #015FA6;
   color: #FFF;
}

.search-bar {
   background-color: #015FA6;
   border: 1px solid rgba(245, 243, 240, 0.062);
   font-size: 0.85em;
   height: 30px;
   padding: 5px;
   color: #FFF;
   font-weight: 600;
}

.search-bar::placeholder {
   color:#d8dee6;
 }
.search-bar:focus::after {
   background-color: #0075C9;
}
.search-bar:focus {
   outline: none;
   border: 1px solid #FFF;
}
.search-btn {
   height: 30px;
   border: none;
   border-left: none;
   border: 1px solid rgba(245, 243, 240, 0.062);
   background-color: #015FA6;
}

.search-btn:hover {
   cursor: pointer;
}

.search-btn:focus {
   background-color: #FFF;
   outline: none;
}
.search-btn.focused {
   background-color: #FFF;
}
.search-icon {
   stroke: #FFF;
}
.search-icon circle {
   fill: none;
}

.search-btn.focused .search-icon, .search-btn:focus .search-icon {
   stroke: #0075C9;
}
.search-btn.focused .search-icon circle, .search-btn:focus .search-icon circle {
   fill: #FFF;
}

.burger {
   background-color: transparent;
   border: none;
   display: none;
   cursor: pointer;
   position: relative;
   height: 50px;
   width: 50px;
   float: right;
   display: flex;
   justify-content: center;
   align-items: center;
}


.burger:focus, .burger:active {
   outline: none;
}

.burger:hover {
   background-color: #0075C9;
   cursor: pointer;
}

.burger-inner {
   height: 30px;
   width: 30px;
   display: grid;
   grid-template-columns: 10px 10px 10px;
   grid-template-rows: 10px 10px 10px;
}

.burger-inner div {
   background-color: #F5F3F0;
   position: relative;
   top: 1px;
   left: 1px;
   height: 5px;
   width: 5px;
   margin: 2px;
   transition: background-color 0.5s;
}
.burger:hover .burger-inner div {
   background-color: #FFF;
}

.search {
   overflow: visible;
}

.search_list_wrap {
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
}
.search_list {
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   position: relative;
   margin-left: 0;
   margin-right: 0;
   padding: 0;
   overflow: visible;
}

.search_list li {
   padding: 0 !important;
   width: 100%;
   height: 100%;
   text-align: left;
}
.search_list li:nth-child(n+2) {
   right: 0;
   position: absolute;
   top: 40px;
   max-width: 300px !important;
}
.search_list li:nth-child(n+3) {
   top: 80px;
}
.search_list li:nth-child(n+4) {
   top: 120px;
}
.search_list li:nth-child(n+5) {
   top: 160px;
}
.search_list li:nth-child(n+6) {
   top: 200px;
}
.search_list li:nth-child(n+7) {
   top: 240px;
}
.search_list li:nth-child(n+8) {
   top: 280px;
}

.search_list li:nth-child(1) {
   display: flex !important;
}

.search_list li:nth-child(n+2) {
   background-color: #FFF;
   display: block !important;
   margin: 0;
   box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
}

.search_list li:nth-child(n+2) a {
   color: #1b2146;
   display: block;
   height: -webkit-fit-content;
   height: -moz-fit-content;
   height: fit-content;
}
.search_list li:nth-child(n+2) a > div {
   color: #1b2146;
}
.search_list li:nth-child(n+2) a:hover {
   background-color: #FFF;
   color: #0075C9;
}
.search_list li:nth-child(n+2) a:hover > div {
   background-color: #FFF;
   color: #0075C9;
}

.search_bar_wrap {
   width: 100%;
   display: flex;
   justify-content: flex-start;
   align-items: center;
}
.search_bar_wrap input {
   display: flex;
   flex-grow: 1;
}
ul.nav_main li.burger-outer {
   display: none;
}

.nav_mobile {
   color: #FFC20E;
   z-index: -1;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   margin: 0;
   padding: 0;
   transition: right 0.5s;
   display: flex;
   flex-direction: column;
   visibility: hidden;
}

.nav_mobile.visible {
   visibility: visible;
   z-index: 9;
}

.nav_mobile img {
   position: absolute;
   bottom: -50px;
   right: -100px;
   max-height: 60vh;
   opacity: 0;
}
.nav_mobile.visible img {
   transition: 0.75s opacity 0.5s;
   opacity: 1;
   z-index: 0;
}

.nav_mobile .wave {
   background-color: #0075C9;
   border: 1px solid #0075C9;
   height: 10%;
   width: 0;
   transition-duration: 1s;
   opacity: 0;
}
.nav_mobile.visible div  {
   transition-duration: 1s;
   opacity: 1;
   width: 100%;
}

.nav_mobile.visible div:nth-child(1) {
   transition-duration: 0.1s;
}
.nav_mobile.visible div:nth-child(2) {
   transition-duration: 0.2s;
}
.nav_mobile.visible div:nth-child(3) {
   transition-duration: 0.3s;
}
.nav_mobile.visible div:nth-child(4) {
   transition-duration: 0.4s;
}
.nav_mobile.visible div:nth-child(5) {
   transition-duration: 0.5s;
}
.nav_mobile.visible div:nth-child(6) {
   transition-duration: 0.6s;
}
.nav_mobile.visible div:nth-child(7) {
   transition-duration: 0.7s;
}
.nav_mobile.visible div:nth-child(8) {
   transition-duration: 0.8s;
}
.nav_mobile.visible div:nth-child(9) {
   transition-duration: 0.9s;
}
.nav_mobile.visible div:nth-child(10) {
   transition-duration: 1s;
}

.nav_mobile div:nth-child(1) {
   transition-duration: 0.1s;
}
.nav_mobile div:nth-child(2) {
   transition-duration: 0.2s;
}
.nav_mobile div:nth-child(3) {
   transition-duration: 0.3s;
}
.nav_mobile div:nth-child(4) {
   transition-duration: 0.4s;
}
.nav_mobile div:nth-child(5) {
   transition-duration: 0.5s;
}
.nav_mobile div:nth-child(6) {
   transition-duration: 0.6s;
}
.nav_mobile div:nth-child(7) {
   transition-duration: 0.7s;
}
.nav_mobile div:nth-child(8) {
   transition-duration: 0.8s;
}
.nav_mobile div:nth-child(9) {
   transition-duration: 0.9s;
}
.nav_mobile div:nth-child(10) {
   transition-duration: 1s;
}

.nav_mobile .office-info {
   position: absolute;
   width: 100%;
   max-width: 325px;
   top: 50px;
   height: 20vh;
}

.nav_mobile .office-info > div {
   position: relative;
   display: inline-block;
   vertical-align: top;
   color: #FFF;
   width: 50%;
   text-align: left;
   left: -50vw;
   opacity: 0;
   transition: 0.25s all 0s;
}

.nav_mobile.visible .office-info > div:nth-child(1) {
   left: 0;
   opacity: 1;
   transition: .5s all 0s;
}
.nav_mobile.visible .office-info > div:nth-child(2) {
   left: 0;
   opacity: 1;
   transition: .5s all 0.1s;
}

.nav_mobile .office-info > div > div {
   margin: 30px;
}

.nav_mobile .office-info > div > div h5 {
   margin: 0;
}

.nav_mobile .office-info > div > div p {
   margin: 0;
   font-size: 12px;
}
.nav_mobile .office-info > div > div a {
   color: #FFF;
   font-size: 12px;
}

.nav_mobile .middle {
   position: absolute;
   top: 30%;
   height: 60%;
   padding: 0;
   margin: 0;
   width: 100%;
}

.nav_mobile .middle li {
   display: flex;
   justify-content: flex-start;
   align-items: flex-start;
   padding-left: 0;
   opacity: 0;
   transition: all 0.25s;
   transition-delay: 0;
   position: relative;
   left: -100vw;
}

.nav_mobile .middle li a {
   display: block;
   width: 100%;
   padding: 10px;
   padding-left: 30px;
   font-size: 1.5em;
   color: #FFF;
   text-align: left;
   text-decoration: none;
   font-weight: 800;
   letter-spacing: -1px;
}

.nav_mobile .middle li a:hover {
   opacity: 0.5;
}

@media only screen and (min-width: 700px) {
   .nav_mobile .middle {
      position: absolute;
      top: 25vh;
   }
   .nav_mobile .middle li a {
      font-size: 2.25em;
      line-height: 1em;
   }
   .nav_mobile img {
      height: 65vh;
   }
}

@media only screen and (max-height: 700px) {
   .nav_mobile .middle li a {
      font-size: 1.5em;
      line-height: 1em;
   }
}

.nav_mobile .bottom {
   position: absolute;
   bottom: 15px;
   height: 15vh;
   padding: 0;
   margin: 0;
   width: 100%;
}

.nav_mobile .bottom li {
   display: flex;
   justify-content: flex-start;
   align-items: flex-start;
   padding-left: 0;
   opacity: 0;
   transition: all 0.25s;
   transition-delay: 0;
   position: relative;
   left: -100vw;
}

.nav_mobile.visible .bottom li {
   left: 30px;
   margin-top: 5px;
}

.nav_mobile .bottom li a {
   text-decoration: none;
   color: #FFF;
   font-size: 12px;
   cursor: pointer;
}
.nav_mobile .bottom li:nth-last-child(1) a {
   font-weight: 600;
}

.nav_mobile.visible ul li {
   opacity: 1;
   transition: all 0.5s;
   left: 0;
}

.nav_mobile.visible .middle li:nth-child(1) {
   transition-delay: 0.2s;
}
.nav_mobile.visible .middle li:nth-child(2) {
   transition-delay: 0.3s;
}
.nav_mobile.visible .middle li:nth-child(3) {
   transition-delay: 0.4s;
}
.nav_mobile.visible .middle li:nth-child(4) {
   transition-delay: 0.5s;
}
.nav_mobile.visible .middle li:nth-child(5) {
   transition-delay: 0.6s;
}
.nav_mobile.visible .middle li:nth-child(6) {
   transition-delay: 0.7s;
}
.nav_mobile.visible .middle li:nth-child(7) {
   transition-delay: 0.8s;
}
.nav_mobile.visible .bottom li:nth-child(1) {
   transition-delay: 0.7s;
}
.nav_mobile.visible .bottom li:nth-child(2) {
   transition-delay: 0.8s;
}
.nav_mobile.visible .bottom li:nth-child(3) {
   transition-delay: 0.9s;
}
.nav_mobile.visible .bottom li:nth-child(4) {
   transition-delay: 1s;
}



.nav_mobile li button {
   display: block !important;
   width: 100% !important;
   padding: 20px;
   background-color: #0075C9;
   border: none;
   color: #FFF;
   font-size: 3em;
   font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   font-weight: 800;
   text-align: left;
   text-decoration: none;
}

.nav_mobile li a:hover {
   color: #FFF;
}


.nav_mobile li button:hover {
   color: #FFF;
}



.nav_main li.services-dropdown {
   position: relative;
}

nav .services {
   display: none;
   z-index: 1; 
   position: fixed;
   top: 50px;
   right: 0px;
   transition: all 0.5s;
   height: 50px;
   background: #FFF;
}
nav .services.visible {
   display: block;
}

nav .services ul {
   height: 50px;
   list-style: none;
   padding: 0;
   margin: 0;
   display: flex;
   box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
}
nav .services ul li {
   display: block;
   margin: 0;
   
}
nav .services ul li:nth-child(2) {
   margin: 0;
}
nav .services ul li a {
   position: relative;
   font-size: 0.85em;
   padding: 0 10px;
   height: 50px;
   font-weight: 600;
   text-decoration: none;
   display: flex;
   color: #0075C9;
}

nav .services ul li a:nth-child(1) {
   padding: 0 10px;
}

.services-toggle {
   display: flex;
   justify-content: center;
   align-items: center;
   width: -webkit-fit-content;
   width: -moz-fit-content;
   width: fit-content;
   white-space: nowrap;
}

nav .services ul li a:hover, nav .services ul li a:nth-child(1):hover {
   color: #0075C9;
   background-color: #F5F3F0;
}
nav .services.visible ul {
   visibility: visible;
   transition-delay: 0.1s;
}

.locations {
   z-index: 1;
   color: #1A1F39;
   background-color: #F5F3F0;
   position: absolute;
   top: 51px;
   width: -webkit-fit-content;
   width: -moz-fit-content;
   width: fit-content;
   right: -1000px;
   transition: all 0.5s;
   padding: 10px;
   border-bottom-left-radius: 3px;
   border-top: none;
   padding-bottom: 1em;
}

.locations.visible {
   right: 0;
}

.locations > div {
   max-width: 200px;
   display: inline-block;
   
}

.locations h5 {
   padding-left: 0.5em;
   margin-block-start: 1em;
   margin-block-end: 0.5em;
   margin-top: 1em;
   margin-bottom: 0.5em;
}

.locations ul {
   margin: 0;
   padding: 0;
   list-style: none;
   display: flex;
   flex-direction: column;
}

.locations ul li {
   display: block !important;
}
.locations ul li a {
   display: block;
   color: #1A1F39;
   font-size: 0.75em;
   padding: 0.5em;
   text-decoration: none;
   height: 100%;
}

.locations ul li a:hover {
   text-decoration: underline;
}

/* @media only screen and (min-width: 960px) and (max-width: 1100px) {
   .nav_main li:nth-child(3) {
      display: none;
   }
} */


@media only screen and (max-width: 1195px) {
   .nav_main li.widescreen {
      display: none;
   }
}
@media only screen and (min-width: 1195px) {
   .nav_main li.services-dropdown {
      display: none;
   }
}


@media only screen and (min-width: 875px) {
   .nav_mobile.visible {
      display: none;
   }
}

@media only screen and (max-width: 875px) {
   .search-bar {
      max-width: 150px;
   }
   .search_bar_wrap {
      justify-content: flex-end;
   }
   .services.visible {
      display: none;
   }
   nav ul li:nth-child(n+3) {
      display: none;
   }
   .burger {
      display: flex;
   }

   .nav_main li:nth-child(2) {
      align-items: flex-end;
      padding-left: 25px;
      padding-right: 0;
      max-width: 1000px;
   }

   .nav_main {
      justify-content: space-between;
   }
   
   ul.nav_main li.burger-outer {
      display: block;
      padding-left: 0;
   }
 }

@media only screen and (max-width: 375px) {
   .search-bar {
      max-width: 100px;
   }
}

html, body, #root {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #FFF;
}

::selection {
  background-color: rgba(33, 206, 153, 0.5);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.component-container {
  width: 100%;
  position: absolute;
  top: 50px;
}

.nav-spacer {
  position: relative;
  height: 50px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.no-scroll {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
