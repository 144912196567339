.home {
    position: relative;
    overflow: hidden;
}
.home .announcement {
    background: #1A1F39;
    font-size: 12px;
    color: #FFF;
}
.home .announcement a {
    font-weight: 600;
    color: #FFF;
}

.home .announcement > div {
    padding: 10px;
}

.home section .page-header {
    z-index: 1;
}
.home section .page-header h1 {
    /* color: #FFF; */
}
.home section .page-header h1 span {
    color: #0075C9;
    /* color: #FFF; */
}
.home section .content {
    height: 100%;
    position: relative;
    z-index: 0;
}
.home section.list-component.services .content {
    display: flex;
}

.home h1 {
    margin: 0;
    line-height: 0.9em;
    font-size: 2.5em;
    letter-spacing: -2px;
}

/* HEADER SECTION */

.home .header img {
    width: 100%;
}

.home .header .content > div {
    width: 100%;
}

.home .header {
    background: rgb(240,252,255);
    background: -moz-linear-gradient(30deg, #F0FCFF 0%, #8CD1F1 100%);
    background: -webkit-linear-gradient(30deg,#F0FCFF 0%, #8CD1F1 100%);
    background: linear-gradient(30deg,#F0FCFF 0%,  #8CD1F1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f0fcff",endColorstr="#008ad0",GradientType=1);
    /* background: #FFF; */
    color: #1A1F39;
    overflow: hidden;
}
.home .header .content {
    position: relative;
} 

/* @keyframes spinningWheel {
    0% {
        transform: rotate(-8deg);
    }
    50% {
        transform: rotate(8deg);
    }
    100% {
        transform: rotate(-8deg);
    }
} */
.home .header .content .text {
    position: absolute;
    width: 500px;
    height: fit-content;
    z-index: 3;
    left: 10%;
    text-align: left;
    top: 0;
    bottom: 20%;
    margin: auto;
    transition: all 0.5s;
}
.home .header .page-header.blue > p {
    /* color: #F0FCFF; */
}
.home .header .content .text p {
    /* color: #F0FCFF; */
}


.home .header .ship img {
    width: auto;
    height: 27.5vw;
    animation: rockTheBoat 10s infinite;
}

@keyframes rockTheBoat {
    0% {
        transform: rotate(-2deg)
    }
    25% {
        transform: rotate(2deg)
    }
    50% {
        transform: rotate(-3deg)
    }
    75% {
        transform: rotate(1deg)
    }
    100% {
        transform: rotate(-2deg)
    }
}

.home .header .blue-spacer {
    background-color: #f5f3f0;
    display: block;
    height: 65vw;
}

/* SERVICES SECTION */
.home section.list-component.services {
    position: relative;
    
}
.home section.list-component.services .content {
    z-index: 2;
}
.services-summary .heading {
    display: flex;
}
.home .spinning-wheel {
    height: 550px;
    position: absolute;
    bottom: -150px;
    right: -200px;
    z-index: 1;
}
.home .spinning-wheel img {
    height: 550px;
    animation: spinningWheel infinite 5s linear;
    opacity: 0.15;
}

/* DIFFERENTIATOR SECTION */

.home .differentiator {
    background-color: #FFF;
    color: #1A1F39;
}

.home .differentiator .content {
    display: flex;
    justify-content: center;
    align-items: center;
}


/* MOBILE */

@media only screen and (max-width: 900px) {
    .home section.list-component.services .content {
        flex-direction: column-reverse;
    }

    .home section.list-component.services .content .divider {
        width: 100%;
        text-align: center;
    }
}
@media only screen and (max-width: 600px) {
    /* .home section {
        height: 80% !important; 
    }  */
    .home h1 {
        font-size: 2em !important;
    }


    .home .header .content .text {
        width: 80%;
        left: 10%;
        text-align: center;
        bottom: 25%;
    }

    .home .header .text p {
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        margin: auto;
        margin-top: 20px;
        text-align: center;
        width: 100%;
    }
}


