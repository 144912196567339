.melbourne.portfolio-services {
    background-color: #F5F3F0;
    padding: 5em 5%;
    padding-top: 75px;
    position: relative;
}
.sydney.portfolio-services {
    background-color: #FFF;
    padding: 5em 5%;
    position: relative;
}

.sydney.portfolio-services > p, .melbourne.portfolio-services > p {
    color: #888995;
    letter-spacing: 2px;
    margin-bottom: 0.75em;
    margin-top: 0.75em;
    font-weight: 900;
}
.sydney.portfolio-services > h1, .melbourne.portfolio-services > h1 {
    margin-bottom: 1em;
}

.melbourne.portfolio-services > div, .sydney.portfolio-services > div {
    height: 300px;
    width: auto;
    position: relative;
    display: inline-block;
    z-index: 1;
}
.sydney.portfolio-services .spacer {
    height: 50px;
    width: 100%;
}

.melbourne.portfolio-services > div > h3, .sydney.portfolio-services > div > h3 {
    position: absolute;
    font-size: 0.95em;
    bottom: 15px;
    left: 20px;
    color: #0075C9;
}
.melbourne.portfolio-services > div > a > svg, .sydney.portfolio-services > div > a > svg  {
    position: absolute;
    bottom: 5px;
    right: -4px;
    transform: scale(0.3);
}

.melbourne.portfolio-services > div > img, .sydney.portfolio-services > div > img {
    height: 300px;
    width: auto;
}

.melbourne.portfolio-services .melbourne-skyline, .sydney.portfolio-services .sydney-skyline  {
    position: absolute;
    bottom: -1px;
    left: 0;
    z-index: 0;
    width: 100%;
    height: auto;
}