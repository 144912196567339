.terms-conditions {
    margin: 3em 0em;
    text-align: left;
    font-size: 12px;
    color: #FFF;
}

.terms-conditions a {
    color: #0075C9;
    font-weight: bold;
}

.terms-conditions h3 {
    font-size: 24px;
}
.terms-conditions h5 {
    font-size: 14px;
}