.centered-list {
    color: #1A1F39;
    text-align: center;
}
.centered-list .title {
    padding: 50px 0;
}
.centered-list .title h1 {
    margin: 0;
    line-height: 0.9em;
    font-size: 2.5em;
    letter-spacing: -2px;
}
.centered-list .title h1 span {
    color: #0075C9;
}

.centered-list ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 0;
    padding-bottom: 100px;
}

.centered-list ul li {
    width: 250px;
    padding: 20px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
    margin: 20px 0px;
    text-align: center;
    position: relative;
}
.centered-list ul li h3 {
    text-align: center;
}
.centered-list ul li .title-underline {
    height: 4px;
    width: 80px;
    background: #0075C9;
    
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}
.centered-list ul li p {
    padding-top: 20px;
}


@media only screen and (max-width: 900px) {
    .centered-list ul {
        flex-direction: column;
        align-items: center;
    }
}