.funds {
    background-color: #F5F3F0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.funds .intro {
    display: flex;
    justify-content: space-between;
    color: #1b2a39;
    width: 100%;
    border-bottom: 1px solid #d8dee6;
    max-width: 1400px;
}

.funds .intro > div {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px 30px;
}

.funds .intro > div h5  {
    letter-spacing: 2px;
    margin-bottom: 0.75em;
    margin-top: 0.75em;
    color: #005FA6;
}
.funds .intro > div h3, .funds .intro > div h5  {
    margin: 0;
}

.funds .intro > div h3  {
    color: #1b2a39;
    font-size: 1.5em;
    text-align: left;
}

.funds .funds-scroll { 
    width: 100%;
    position: relative;
}

.funds .intro p {
    font-size: 0.75em;
    text-align: right;
    margin: 0;
    opacity: 0.75;
}

.funds .bg {
    z-index: 1;
    position: absolute;
    height: 50vh;
    width: 60vw;
    left: 0;
    right: 0;
    margin: auto;
    top: 50px;
    background-color: #FFC20E;
    background-color: #0075C9;
}
.funds-list {
    z-index: 2;
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    overflow: hidden;
    padding-bottom: 200px;
    height: 450px;
}

.info-table li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1em;
    list-style: none;
    border: none;
    border-bottom: 1px solid #d8dee6;
}
.investment-approach {
    margin: 0;
    padding: 0;
    text-align: left;
    list-style: auto;
}

.investment-approach ul li {
    margin: 0;
    padding: 0;
    width: 100%;
    border: none;
}

.funds-scroll .bottom-nav {
    position: absolute;
    z-index: 2;
    bottom: 50px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
}

.funds-scroll .bottom-nav .current-position {
    display: flex;
    align-items: center;
}

.funds-scroll .bottom-nav .current-position > div {
    width: 50px;
    height: 3px;
    margin: 10px;
    background-color: #005FA6;
}

.funds-scroll .bottom-nav .next {
    margin-right: 5%;
    padding-left: 30px;
}

.funds-scroll .bottom-nav .next p {
    margin-right: 20px;
    font-weight: 600;
}

.funds-scroll .bottom-nav .prev {
    margin-left: 5%;
}

.funds-scroll .bottom-nav .prev p {
    margin-left: 20px;
    font-weight: 600;
}

.funds-scroll .bottom-nav .next, .funds-scroll .bottom-nav .prev {
    display: flex;
}

.funds-scroll .bottom-nav button {
    background-color: #FFC20E;
    background-color: #0075C9;
    height: 50px;
    width: 50px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.funds-scroll .bottom-nav button > div {
    background-color: #FFF;
    height: 2px;
    width: 7px;
    position: relative;
}

.funds-scroll .bottom-nav .prev button > div:nth-child(1) {
    transform: rotate(-45deg);
    left: 2px;
    bottom: 2px;
}

.funds-scroll .bottom-nav .prev button > div:nth-child(2) {
    transform: rotate(45deg);
    right: 5px;
    top: 2px;
}

.funds-scroll .bottom-nav .next button > div:nth-child(1) {
    transform: rotate(45deg);
    left: 5px;
    bottom: 2px;
}

.funds-scroll .bottom-nav .next button > div:nth-child(2) {
    transform: rotate(-45deg);
    right: 2px;
    top: 2px;
}

.funds .contact-us {
    display: flex;
    justify-content: space-between;
    background-color: #0d122c;
    height: 700px;  
    width: 100%;  
}

@media screen and (max-width: 700px) {
    .funds-scroll .bottom-nav p {
        display: none;
    }
    .funds-scroll .bottom-nav .current-position > div {
        width: 20px;
        margin: 5px;
    }
    .funds-scroll .bottom-nav .next {
        padding-left: 0px;
    }
}


@media screen and (min-width: 1400px) {
    .funds .intro > div {
        padding: 20px 10px;
    }
}