.fsg {
    height: 300px;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #0d122c;
}
.fsg h3 {
    margin-top: 3em;
    font-size: 24px;
    color: #FFF;
}

.fsg div {
    width: 500px;
    text-align: left;
    padding: 0.5em 0;
}
.fsg a {
    padding: 0.5em 0;
    font-weight: 600;
    text-decoration: none;
    color: #0075C9;
    font-weight: bold;
}

.fsg a svg { 
    transform: scale(0.75);
    position: relative;
    left: 10px;
    top: 5px;
}