.line-graph-wrapper {
    display: block;
    position: relative;
    color: #4b4f56;
    background-color: #FFF;
    margin-bottom: 8px;
    counter-reset: section 2;
    font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    font-size: 10px;
    width: 100%;
    /* max-width: 1199px; */
    border-radius: 4px;
    
}

.line-graph-wrapper .graph-line {
    stroke: #0075C9;
}

.line-graph-wrapper .graph-line-fill {
    stroke: #0075C9;
    stroke-width: 1px;
    fill: #E9F2F9;
    /* fill-opacity: 0.1; */
}

.line-graph-wrapper .option-toggles {
    display: flex;
    justify-content: flex-end;
}

.line-graph-wrapper .fund-type {
    width: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.line-graph-wrapper .time-periods {
    width: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.line-graph-wrapper .fund-type div {
    -webkit-box-flex: 1;
    position: relative;
    width: 100px;
}

.line-graph-wrapper .time-periods div {
    -webkit-box-flex: 1;
    position: relative;
    width: 50px;
}

.line-graph-wrapper .time-periods div button, .line-graph-wrapper .fund-type div button {
    display: block;
    position: relative;
    width: 100%;
    font: inherit;
    color: inherit;
    padding: 6px 3px;
    -webkit-font-smoothing: inherit;
    letter-spacing: inherit;
    cursor: pointer;
    overflow: visible;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    outline: none;
    font-size: 1.2em;
    background: none;
    border-bottom: 1px solid #F5F3F0;
}

.line-graph-wrapper .time-periods div button.selected, .line-graph-wrapper .fund-type div button.selected {
    color: #0075C9;
    font-weight: bold;
}

.line-graph-wrapper .time-periods div button.selected::after, .line-graph-wrapper .fund-type div button.selected:after {
    position: absolute;
    content: " ";
    display: block;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 3px;
    background: #0075C9;
}

.line-graph-wrapper .top-axis, .line-graph-wrapper .graph-area {
    cursor: grab;
}
.line-graph-wrapper .top-axis:active, .line-graph-wrapper .graph-area:active {
    cursor: grabbing;
}
.line-graph-wrapper .body {
    position: relative;
    user-select: none;
    min-height: 350px;
    padding-top: 16px;
}


.line-graph-wrapper .header-wrapper .current-x {
    position: absolute;
    top: 20px;
}

.line-graph-wrapper .header-wrapper .close {
    left: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    position: absolute;
    right: auto;
    padding: 4px 8px;
    border-radius: 3px 3px 0px 0px;
    background: rgba(245, 243, 240, 0.5);
}
.line-graph-wrapper .header-wrapper .close.switched {
    left: auto;
    right: 0px;
}

.line-graph-wrapper .header-wrapper .close p {
    font-weight: normal;
    text-align: right;
    white-space: nowrap;
    line-height: 1.4;
    font-size: 1.2em;
    margin: 0px;
    color: #0075C9;
}
.line-graph-wrapper .header-wrapper .close p > strong {
    font-weight: 900;
    font-size: 1.2em;
    
    color: #1b2a39;
}

.line-graph-wrapper .annotations {
    text-align: right;
}
.line-graph-wrapper .annotations svg {
    margin-right: 4px;
}

.line-graph-wrapper .annotations button {
    cursor: pointer;
    margin-left: 2px;
    margin-bottom: 2px;
    font-size: 1.2em;
    color: #4b4f56;
    text-transform: capitalize;
    padding: 4px 10px 4px 8px;
    background: #F5F6F7;
    border-width: 1px;
    border-style: solid;
    border-color: #F5F6F7;
    border-image: initial;
    border-radius: 100px;
    outline: none;
}

.line-graph-wrapper .annotations button.hidden {
    color: #AAA;
    background: #FFF;
}

.line-graph-wrapper .annotations button.hidden svg * {
    fill: #FFF;
}

.create-annotation {
    cursor: pointer;
    color: #4b4f56;
    text-transform: capitalize;
    padding: 4px;
    background: #FFF;
    border-width: 1px;
    border-style: solid;
    border-color: #F5F6F7;
    border-image: initial;
    border-radius: 100px;
    outline: none;
}
.create-annotation input, .create-annotation button {
    font-size: 1.2em;
    color: #4b4f56;
    padding: 4px 10px 4px 8px;
    background: #FFF;
    border-width: 1px;
    border-style: solid;
    border-color: #F5F6F7;
    border-radius: 100px;
    outline: none;
}

.annotation-popup-bg {
    z-index: 10;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100%;
    background-color: rgba(0,0,0,0.25);
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.annotation-popup {
    background-color: #FFF;
    position: relative;
    display: block;
    width: 100%;
    height: fit-content;
    min-height: 0px;
    max-width: 700px;
}

.annotation-popup-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: black;
    overflow-y: auto;
    overflow-x: hidden;
    background: white;
    padding: 20px 24px;
}
.annotation-popup-body .linked-document {
    display: flex;
    flex-direction: column;
}
.annotation-popup-body .linked-document embed {
    height: 400px;
    margin: 20px 0;
}

.annotation-popup-body .linked-document a {
    position: relative;
    line-height: 1;
    user-select: none;
    vertical-align: top;
    font-size: 1rem;
    height: 40px;
    color: #0075C9;

    flex: 1 1 0%;
    background: #FFF;
    border: 1px solid #0075C9;
    text-decoration: none;
    padding: 10px 16px;
    outline: none;
}


.annotation-popup-body h3 {
    font-size: 1.1rem;
}
.annotation-popup-body p {
    font-size: 0.8rem;
    line-height: 1.5;
}

.annotation-popup header {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    border-bottom: 1px solid rgb(232, 232, 232);
    font-size: 1rem;
    font-weight: 300;
    
}
.annotation-popup header h3 {
    margin: 5px 0px;
}

.annotation-popup header button {
    background: transparent;
    outline: none;
    border: none;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.annotation-popup header button div {
    background: #000;
    height: 15px;
    width: 2px;
}
.annotation-popup header button div:nth-child(1) {
    transform: rotate(45deg) translateX(1.5px);
}
.annotation-popup header button div:nth-child(2) {
    transform: rotate(-45deg) translateX(-1.5px);
}

.annotation-popup footer {
    display: flex;
    justify-content: center;
    padding: 16px 24px;
    border-top: 1px solid rgb(232, 232, 232);
}

.annotation-popup footer button {
    position: relative;
    line-height: 1;
    user-select: none;
    vertical-align: top;
    font-size: 1rem;
    height: 40px;
    color: white;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    flex: 1 1 0%;
    background: #0075C9;
    padding: 0px 16px;
    border: none;
    outline: none;
}

@media (min-width: 768px) {
    .annotation-popup-bg {
        padding: 32px;
    }
}

@media (max-width: 700px) {
    .line-graph-wrapper .fund-type div:nth-child(1) {
        width: 60px;
    }
}